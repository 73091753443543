@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.sortie_param {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  background-color: $primary-white-color;
  width: 100%;
  gap: 10px;
  padding-bottom: 100px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 13px;
      margin-left: 10px;
    }

    @include md {
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;

      .header {
        width: 100%;
        justify-content: space-between;
        flex-direction: row-reverse;
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      .Searchbar {
        background-color: #e8edf2;
        // width: 100%;
        display: flex;
        align-items: center;
        padding: 6px;
        border-radius: 10px;

        .icon {
          color: #4f7396;
          font-size: 18px;
        }

        input {
          width: 100%;
          border: none;
          padding-left: 5px;
          font-size: 14px;
          color: #4f7396;
          background-color: transparent;

          &:focus {
            outline: none;
          }

          &::placeholder {
            font-size: 12px;
            color: #4f7396;
          }
        }
      }

      .ico {
        color: $secondary-black-color;
        cursor: pointer;
      }
    }
  }

  .container {
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
    gap: 5px;
    overflow-x: hidden;
    @include xl {
      flex-direction: column;
      flex-direction: column-reverse;
    }

    .list {
      display: flex;
      flex: 2;
      flex-direction: column;
      gap: 5px;
      padding-bottom: 10px;
      width: 100%;
      height: 100%;
      // span {}

      .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
        .css-quj9j6-MuiTable-root {
          min-width: 350px;
        }
      }

      // .css-1ex1afd-MuiTableCell-root{
      //     padding: 5px;
      // }
    }

    .add {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 20px 20px 20px 20px;
      // box-shadow: 1px 2px 2px #c5c1c1;
      border-radius: 5px;
      margin: 10px;

      @include xl {
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        padding: 10px;
      }

      span {
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        margin: 20px 10px;

        @include xl {
          margin: 10;
          width: 100%;
        }
      }

      .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        @include xl {
          flex-direction: row;
          justify-content: center;
          width: 100%;
        }

        .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input,
        .css-1ndpvdd-MuiTableCell-root,
        .css-1yhpg23-MuiTableCell-root {
          height: 20px;
        }

        button {
          height: 35px;
          width: 100%;
          cursor: pointer;
          border: none;
          background-color: $secondary-green-color;
          color: $primary-white-color;

          &:hover {
            border: 1px solid $secondary-green-color;
            background-color: transparent;
            color: $secondary-green-color;
          }
        }
        .loadingBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 35px;
          width: 100%;
          cursor: pointer;
          border: none;
          background-color: $secondary-green-color;
          .circular {
            color: $primary-white-color;
          }

          &:hover {
            border: 1px solid $secondary-green-color;
            background-color: $secondary-green-color;
          }
        }
      }
    }
  }
}
