.chartContainer {
  display: flex;
  flex-direction: column;

  .chartHeader_sk {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .chartHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: #bcbcbc;
    }
    select {
      padding: 3px 5px;
      border: none;
      background-color: transparent;
      border: 1px solid #cdcdcd;
      border-radius: 4px;
      color: #767575;
      outline: none;
      width: fit-content;
      margin-top: -10px;
    }
  }
}
