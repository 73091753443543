@import '../../../styles/variables.scss';

.list_autre_entree {
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-width: 100%;

    .search {
        // padding-left: 20px;
        // position: absolute;
        // top: 2.4%
        width: 100%;
    }
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .produits {
            display: flex;
            align-items: center;
            gap: 5px;
            justify-content: space-between;

            span {
                padding: 5px 8px;
                font-size: 12px;
                font-weight: 100;
                color: $primary-green-color;
                background-color: $soft-green-color;
            }
        }
    }

    .table_list {
        .css-6qfsqn-MuiTableCell-root {
            font-weight: 500;
        }

        .css-dv0mq-MuiTableCell-root {
            padding: 18px 5px;
            font-size: 12px;

            .status {
                padding: 2px 5px;
                border-radius: 5px;

                &.validate {
                    font-size: 10px;
                    color: $primary-green-color;
                    background-color: $soft-green-color;
                }

                &.pending {
                    font-size: 10px;
                    color: $primary-orange-color;
                    background-color: $soft-orange-color;
                }

                &.canceled {
                    font-size: 10px;
                    color: $primary-red-color;
                    background-color: $soft-red-color;
                }

            }

        }


    }

}