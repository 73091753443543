@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.update_pswd {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101c8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;

  .dialog {
    // background-color: aliceblue;
    background-color: white;
    width: 50vw;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    // border-radius: 15px;
    padding: 15px;

    @include md {
      width: 65vw;
    }
    @include sm {
      width: 95vw;
    }

    .header_pswd {
      padding: 5px 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $secondary-white-color;

      .header_left {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
      }

      .ico_close {
        font-size: 20px;
        font-weight: bolder;
        color: $secondary-green-color;
        cursor: pointer;
      }
    }

    .body {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      width: 100%;
      margin-bottom: 20px;

      .label {
        display: flex;
        align-items: center;
        gap: 5px;
        .ico {
          font-size: 24px;
        }

        span {
          text-decoration: underline;
          letter-spacing: 1px;
          font-size: 14px;
        }
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 5px;

        .inputContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid grey;
          width: 50%;
          height: 35px;
          padding: 5px 10px;

          @include sm {
            width: 50%;
          }
          @include md {
            width: 65%;
          }
          @include lg {
            width: 55%;
          }
          @include xl {
            width: 60%;
          }
        }

        input {
          height: 30px;
          width: 100%;
          background-color: transparent;
          outline: none;
          border: none;
          font-family: PoppinsRegular;
        }
        .icoPas {
          font-size: 20px;
          color: grey;
          cursor: pointer;

          @include sm {
            display: none;
          }
        }
      }

      button {
        background-color: $secondary-green-color;
        border: 1px solid $secondary-green-color;
        color: $primary-white-color;
        width: fit-content;
        padding: 8px 60px;
        cursor: pointer;
        font-family: PoppinsRegular;

        @include sm {
          padding: 8px 0px;
        }
        @include md {
          padding: 8px 0px;
        }
        @include xl {
          width: 45%;
        }

        &:hover {
          background-color: transparent;
          border: 1px solid $secondary-green-color;
          color: $secondary-green-color;
        }
      }
    }
  }
}
