@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.recent_order {
  flex: 1;
  margin-top: 20px;
  position: sticky;
  top: 5px;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  background-color: $primary-white-color;
  border-radius: 10px;

  @include md {
    display: none;
  }

  .content {
    padding: 20px;

    h4 {
      color: $secondary-green-color;
      font-size: 12px;
    }

    span {
      font-size: 12px;
      color: $primary-black-color;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 2px;

      li {
        list-style: none;
        text-decoration: none;
        border-bottom: 1px solid #dadada;
        cursor: pointer;

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 5px;

          .left {
            display: flex;
            flex-direction: column;
            gap: 5px;

            span {
              color: $secondary-black-color;

              &:first-child {
                font-weight: bold;
              }
            }
          }

          .plus_btn {
            font-size: 12px;
            color: $secondary-black-color;
            text-decoration: underline;
            cursor: pointer;

            .ico {
              font-size: 15px;
              color: $secondary-black-color;
            }
          }
        }
      }
    }
  }

  .footer {
    padding: 20px 10px;
    border-top: 1px solid #d7d7d7;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      font-size: 12px;
      font-weight: bold;
      color: $secondary-green-color;
      cursor: pointer;
    }

    .ico {
      font-size: 12px;
      color: $secondary-green-color;
    }
  }
}
