@import "../../styles/responsive.scss";

.nodata {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
  // height: 300px;
  height: 150px;

  @include sm {
    span {
      font-size: 10px;
    }
  }
}
