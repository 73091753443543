@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.single_client_page {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;

      h3 {
        font-size: 14px;

        @include lg {
          font-size: 14px;
        }

        @include md {
          font-size: 12px;
        }
      }

      .ico {
        font-size: 17px;
        @include md {
          font-size: 12px;
        }
      }
    }

    .right {
      display: flex;
      .btn_commande {
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 6px 10px;
        border: 1px solid $secondary-black-color;
        cursor: pointer;
        background-color: $secondary-green-color;
        color: $primary-white-color;

        .ico {
          font-size: 14px;
        }
      }
      //   .button_send_mess {
      //     border-radius: 4px;
      //     cursor: pointer;
      //     padding: 6px;
      //     font-size: 12px;
      //     color: $primary-black-color;
      //     background-color: transparent;
      //     border: 1px solid $secondary-black-color;
      //     display: flex;
      //     align-items: center;
      //     gap: 5px;

      //     .ico {
      //       font-size: 17px;
      //     }

      //     &:hover {
      //       box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      //     }
      //   }
    }
  }

  .container {
    display: flex;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
    background-color: $primary-white-color;
    border-radius: 10px;
    position: relative;

    @include md {
      flex-direction: column;
      gap: 20px;
    }

    .left {
      flex: 1;
      position: sticky;
      align-self: flex-start;
      top: 0px;

      @include md {
        position: unset;
        width: 100%;
      }

      .top {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .box {
          display: flex;
          align-items: center;
          gap: 15px;

          .box_icon {
            width: 40px;
            height: 40px;
            background-color: #dfe4e1;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            .ico2 {
              color: $secondary-black-color;
            }
          }

          .box_info {
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: flex-start;

            .main_info {
              font-weight: bold;
            }

            .second_info {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              span {
                color: $secondary-black-color;
                font-size: 12px;
              }
            }
          }
        }

        .avatar {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 5px;

          @include md {
            align-items: center;
          }

          .ico {
            width: 50px;
            height: 50px;
            background-color: #dfe4e1;
          }

          .username {
            font-weight: bold;
            font-size: 18px;
            &.Ese {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 5px;
              .container-Ese {
                width: 15px;
                height: 15px;
                background-color: #359708;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                .Ese {
                  font-size: 11px;
                  font-weight: normal;
                }
              }
            }

            @include md {
              font-size: 14px;
            }
          }

          .phone {
            font-weight: normal;
            color: $secondary-black-color;

            &.Ese {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 5px;
              .container-Ese {
                width: 15px;
                height: 15px;
                background-color: #359708;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                .Ese {
                  font-size: 11px;
                  font-weight: normal;
                }
              }
            }
          }

          .status {
            display: flex;
            align-items: center;
            gap: 5px;

            .info {
              color: $secondary-black-color;
              font-size: 12px;
            }

            &.active {
              padding: 2px 5px;
              font-size: 11px;
              // background-color: $soft-green-color;
              background-color: #3d9e6949;
              border-radius: 3px;
              color: $primary-green-color;
            }

            &.inactive {
              padding: 2px 5px;
              font-size: 11px;
              // background-color: $soft-red-color;
              background-color: #3d9e6949;
              border-radius: 3px;
              color: $primary-red-color;
            }
          }
        }

        .body {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;

          .box {
            display: flex;
            align-items: center;
            gap: 15px;

            .box_icon {
              width: 40px;
              height: 40px;
              background-color: #dfe4e1;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;

              .ico2 {
                color: $secondary-black-color;
              }
            }

            .box_info {
              display: flex;
              flex-direction: column;
              gap: 5px;
              align-items: flex-start;

              .main_info {
                font-weight: bold;
              }

              .second_info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                span {
                  color: $secondary-black-color;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }

    .right {
      flex: 1;

      .state_items {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .title {
          display: flex;
          align-items: center;
          gap: 10px;

          .ico {
            font-size: 15px;
          }
          span {
            font-size: 14px;
            font-weight: 500;
          }
        }

        .state {
          display: flex;
          gap: 5px;
          // justify-content: space-between;
          .state_item {
            display: flex;
            justify-content: space-between;
            padding: 5px 8px;
            width: 110px;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
              rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

            .icon {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;

              &.cmd {
                color: #3d9e68e8;
                border: 2px solid #3d9e68e8;
              }
              &.waiting {
                color: #9e913de8;
                border: 2px solid #9e913de8;
              }
              &.payed {
                color: #3d9e68e8;
                border: 2px solid #3d9e68e8;
              }
              &.balance {
                color: #b72e24e8;
                border: 2px solid #b72e24e8;
              }

              .ico {
                font-size: 22px;

                &.cmd {
                  color: #3d9e68e8;
                }
                &.waiting {
                  color: #9e913de8;
                }
                &.payed {
                  color: #3d9e68e8;
                }
                &.balance {
                  color: #b72e24e8;
                }
              }
            }

            .detail {
              display: flex;
              flex-direction: column;

              .item_value {
                font-weight: bolder;
                font-size: 13px;

                &.cmd {
                  color: #3d9e68e8;
                }
                &.waiting {
                  color: #9e913de8;
                }
                &.payed {
                  color: #3d9e68e8;
                }
                &.balance {
                  color: #b72e24e8;
                }
              }
              .item_label {
                font-size: 11px;
              }
            }
          }
        }
      }

      .history {
        padding-top: 20px;
        .title {
          display: flex;
          align-items: center;
          gap: 10px;

          .ico {
            font-size: 20px;
          }
          span {
            font-size: 14px;
            font-weight: 500;
          }
        }

        ul {
          margin-top: 10px;

          li {
            list-style-type: none;
            position: relative;
            width: 2px;
            padding-top: 20px;
            background-color: $primary-green-color;
            cursor: pointer;
            &::after {
              content: "";
              position: absolute;
              bottom: 0px;
              left: 50%;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: $primary-green-color;
              transform: translateX(-50%);
            }

            .icons {
              width: 100%;
              height: 200px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            div {
              min-width: 400px;
              width: 400px;
              padding: 8px;
              background-color: #5bca2b23;
              border-radius: 0 5px 5px 0;

              @include md {
                min-width: 350px;
                width: max-content;
              }

              p {
                margin-bottom: 5px;
                font-size: 12px;
                display: flex;
                align-items: center;

                .ico {
                  font-size: 18px;
                }
              }

              h4 {
                font-family: "poppins", "poppins", Verdana, sans-serif;
                font-size: 14px;
                font-weight: normal;
              }

              time {
                font-size: 10px;
              }
              .balance {
                margin-left: 20px;
                font-size: 10px;
                padding: 1px 3px;
                background-color: #eb2a2483;
                color: #f8faf7;
                border-radius: 2px;
              }
              .status {
                margin-left: 20px;
                font-size: 10px;
                padding: 1px 3px;
                background-color: #4bc41b36;
                color: #084317;
                border-radius: 2px;
              }
              .waiting {
                margin-left: 20px;
                font-size: 10px;
                padding: 1px 3px;
                background-color: #d7cb46a6;
                color: #433e0e;
                border-radius: 2px;
              }
            }
          }
        }
      }
    }
  }
}
