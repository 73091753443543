@import "../../styles/variables.scss";

.alert {
  .container {
    top: 10%;
    right: 20px;
    position: fixed;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 5px $secondary-black-color;
    width: fit-content;
    background-color: $primary-white-color;
    border-radius: 15px;
    gap: 10px;
    padding: 4px;
    animation: notify 0.3s ease-out;
    z-index: 9999;

    .icon_container {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: $primary-green-color;
      display: flex;
      justify-content: center;
      align-items: center;

      .ico {
        color: $primary-white-color;
        font-size: 16px;
      }
    }

    p {
      font-size: 12px;
    }

    .close {
      display: flex;
      align-items: center;
      justify-content: center;

      .ico {
        font-size: 18px;
        cursor: pointer;
        font-weight: 800;
        color: $secondary-black-color;
      }
    }

    @keyframes notify {
      0% {
        top: -10%;
        opacity: 0;
        scale: 0;
      }

      100% {
        top: 10%;
        opacity: 1;
        scale: 1;
      }
    }
  }

  .success {
    @extend .container;

    .icon_container {
      background-color: $primary-green-color;
    }
  }

  .info {
    @extend .container;

    .icon_container {
      background-color: #4159e4;
    }
  }

  .warn {
    @extend .container;

    .icon_container {
      background-color: #d1a13b;
    }
  }

  .fail {
    @extend .container;

    .icon_container {
      background-color: $primary-red-color;
    }
  }
}
