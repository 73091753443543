@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.single_order_page {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    // @include md {
    //   flex-direction: column;
    //   align-items: flex-start;
    //   gap: 10px;
    // }

    .left {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;

      h3 {
        font-size: 14px;

        @include lg {
          font-size: 14px;
        }

        @include md {
          font-size: 12px;
        }
      }

      .nom {
        font-size: 14px;
        font-family: MonserratRegular;
        // color: #007d00;
        // font-weight: bold;
      }
      .userIco {
        font-family: MonserratRegular;
        // color: #007d00;
      }

      .ico {
        font-size: 17px;

        @include md {
          font-size: 10px;
        }
      }
    }

    .right {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      @include md {
        width: 100%;
        justify-content: flex-end;
      }

      .btn_validate {
        font-size: 12px;
        padding: 6px 10px;
        border: 1px solid $secondary-black-color;
        cursor: pointer;

        display: flex;
        align-items: center;

        span {
          font-size: 13px;
        }

        .ico {
          font-size: 15px;
        }
      }

      .btn_update {
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 6px 10px;
        border: 1px solid $secondary-green-color;
        cursor: pointer;
        background-color: transparent;
        color: $secondary-green-color;
        gap: 3px;

        .ico {
          font-size: 16px;
        }

        &.disabled {
          background-color: transparent;
          color: #9c9c9c;
          border: 1px solid #9c9c9c;
          cursor: not-allowed;
        }
      }

      .btn_commande {
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 6px 10px;
        border: 1px solid $secondary-black-color;
        cursor: pointer;
        background-color: $secondary-green-color;
        color: $primary-white-color;

        .ico {
          font-size: 14px;
        }
      }
    }
  }

  .container_ {
    height: 100%;
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;

    border-radius: 15px;

    @include md {
      width: 80vw;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $secondary-black-color;
      padding: 15px 20px;
      border-bottom: 1px solid #e3dfdf;

      @include md {
        padding: 10px 20px;
      }

      .title {
        padding: 5px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        .ico {
          font-size: 17px;
          color: $primary-black-color;
        }
        span {
          color: $primary-black-color;
          font-size: 15px;
        }

        .iconoir--twitter-verified-badge {
          display: inline-block;
          width: 1rem;
          height: 1rem;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='%23016a13' stroke-width='2.55'%3E%3Cpath d='M10.521 2.624a2 2 0 0 1 2.958 0l1.02 1.12a2 2 0 0 0 1.572.651l1.513-.07a2 2 0 0 1 2.092 2.09l-.071 1.514a2 2 0 0 0 .651 1.572l1.12 1.02a2 2 0 0 1 0 2.958l-1.12 1.02a2 2 0 0 0-.651 1.572l.07 1.513a2 2 0 0 1-2.09 2.092l-1.514-.071a2 2 0 0 0-1.572.651l-1.02 1.12a2 2 0 0 1-2.958 0l-1.02-1.12a2 2 0 0 0-1.572-.651l-1.513.07a2 2 0 0 1-2.092-2.09l.071-1.514a2 2 0 0 0-.651-1.572l-1.12-1.02a2 2 0 0 1 0-2.958l1.12-1.02a2 2 0 0 0 .651-1.572l-.07-1.513a2 2 0 0 1 2.09-2.092l1.514.071a2 2 0 0 0 1.572-.651z'/%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m9 12l2 2l4-4'/%3E%3C/g%3E%3C/svg%3E");
        }

        .iconoir--twitter-verified-badge_rouge {
          display: inline-block;
          width: 1rem;
          height: 1rem;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='%23e4ad06' stroke-width='3'%3E%3Cpath d='M10.521 2.624a2 2 0 0 1 2.958 0l1.02 1.12a2 2 0 0 0 1.572.651l1.513-.07a2 2 0 0 1 2.092 2.09l-.071 1.514a2 2 0 0 0 .651 1.572l1.12 1.02a2 2 0 0 1 0 2.958l-1.12 1.02a2 2 0 0 0-.651 1.572l.07 1.513a2 2 0 0 1-2.09 2.092l-1.514-.071a2 2 0 0 0-1.572.651l-1.02 1.12a2 2 0 0 1-2.958 0l-1.02-1.12a2 2 0 0 0-1.572-.651l-1.513.07a2 2 0 0 1-2.092-2.09l.071-1.514a2 2 0 0 0-.651-1.572l-1.12-1.02a2 2 0 0 1 0-2.958l1.12-1.02a2 2 0 0 0 .651-1.572l-.07-1.513a2 2 0 0 1 2.09-2.092l1.514.071a2 2 0 0 0 1.572-.651z'/%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m9 12l2 2l4-4'/%3E%3C/g%3E%3C/svg%3E");
        }
      }

      .right_side_title {
        display: flex;
        align-items: center;
        gap: 5px;

        .selectLabel {
          padding: 0 5px;
          // background-color: $primary-white-color;
          background-color: #e8edf2;

          border-radius: 10px;
          // border: 1px solid #ccc;

          select {
            padding: 6px;
            border: none;
            outline: none;
            // background-color: $primary-white-color;
            background-color: #e8edf2;

            // color: $secondary-black-color;
            font-size: 14px;
            border-radius: 10px;
            color: #4f7396;
          }
        }
      }

      .ico {
        font-size: 22px;
        font-weight: bold;
        color: $secondary-black-color;
        cursor: pointer;
      }
    }

    .left {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 20px;

      @include md {
        flex-direction: column;
        padding: 10px;
        gap: 10px;
      }

      .top {
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
        flex: 2;

        .box {
          display: flex;
          align-items: center;
          gap: 15px;

          .box_icon {
            width: 40px;
            height: 40px;
            background-color: #dfe4e1;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include md {
              width: 30px;
              height: 30px;
            }

            .ico2 {
              color: $secondary-black-color;

              @include md {
                font-size: 15px;
              }
            }
          }

          .box_info {
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: flex-start;

            .main_info {
              font-weight: bold;
              @include md {
                font-size: 11px;
              }
            }

            .second_info {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              span {
                color: $secondary-black-color;
                font-size: 12px;
              }
            }
            .status_info {
              padding: 4px 8px;
              background-color: $primary-white-color;
              border-radius: 10px;
              border: 1px solid #ccc;
              color: $secondary-black-color;
              font-size: 14px;

              &.paiement {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 6px 20px;
                cursor: pointer;

                .ico {
                  color: green;
                }
              }
            }
          }
        }
      }

      .body {
        display: flex;
        flex: 3;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        .content {
          display: flex;
          width: 98%;
          background-color: $soft-orange-color;
          align-items: flex-start;
          flex-wrap: wrap;
          gap: 15px;
          padding: 20px;
          border-radius: 5px;

          .box {
            display: flex;
            align-items: center;
            gap: 15px;
            width: 45%;

            &.nature {
              width: 100%;
              border-bottom: 2px solid #d0d0d0;
              padding-bottom: 20px;
            }

            .box_icon {
              width: 30px;
              height: 30px;
              background-color: #dfe4e1;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;

              .ico2 {
                color: $secondary-black-color;
                font-size: 18px;
              }
            }

            .box_info {
              display: flex;
              // flex-direction: row;
              flex-direction: column;
              gap: 5px;
              align-items: flex-start;

              .main_info {
                font-weight: bold;

                @include md {
                  font-size: 11px;
                }
                @include lg {
                  font-size: 12px;
                }
              }

              .second_info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                &.reste {
                  background-color: rgba(240, 12, 12, 0.829);
                  padding: 2px 4px;

                  span {
                    color: $secondary-white-color;
                    font-size: 12px;
                  }
                }

                span {
                  color: $secondary-black-color;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
      padding-bottom: 15px;
      // padding-top: 20px;
      // border-top: 1px solid $secondary-green-color;

      .btns {
        display: flex;
        justify-content: center;
        gap: 10px;
        button {
          display: flex;
          align-items: center;
          gap: 5px;
          border: none;
          padding: 5px 10px;
          background-color: transparent;
          cursor: pointer;
          margin-bottom: 10px;
          font-size: 12px;

          @include sm {
            span {
              font-size: 10px;
            }
          }

          span {
            font-size: 12px;
          }
          &.returned {
            border: 1px solid $secondary-black-color;
            color: $secondary-black-color;
            background-color: transparent;

            &:hover {
              border: 1px solid $primary-black-color;
              color: $primary-black-color;
            }
          }

          &.reserved {
            background-color: $secondary-green-color;
            border: 1px solid $secondary-green-color;
            color: $primary-white-color;

            &:hover {
              background-color: transparent;
              color: $secondary-green-color;
            }
          }
          &.canceled {
            background-color: transparent;
            border: 1px solid $primary-red-color;
            color: $primary-red-color;
            transition: 0.2s ease;

            &:hover {
              background-color: rgb(255, 0, 0);
              color: $primary-white-color;
            }
          }

          &.settled {
            background-color: $secondary-green-color;
            border: 1px solid $secondary-green-color;
            color: $primary-white-color;

            &:hover {
              background-color: transparent;
              color: $secondary-green-color;
            }
          }
        }
      }
      .intput_validation {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;
        gap: 5px;
        border-top: 1px solid $secondary-green-color;
        margin-top: 20px;
        padding-top: 20px;

        .left_input {
          text-align: center;
          margin-left: 5px;

          input {
            height: 30px;
            outline: none;
            padding: 0 5px;
            width: 140px;
            text-align: center;
            font-size: 14px;
          }

          button {
            border: none;
            height: 30px;
            padding: 0 10px;
            background-color: $secondary-green-color;
            color: $secondary-white-color;
            font-weight: bold;
            border: 1px solid $secondary-green-color;
            cursor: pointer;
          }
        }
        .total_reste {
          // background-color: $soft-green-color;
          padding: 10px 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 10px;
          font-style: oblique;
          // border-radius: 1px;

          .errorMsg {
            display: flex;
            align-items: center;
            font-family: MonserratRegular;
            font-size: 12px;
            color: #de2424;
            gap: 5px;
            .icon {
              color: #de2424;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
