@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
    url("./fonts/poppins/PoppinsRegular.ttf") format("ttf"),
    url("./fonts/poppins/PoppinsRegular.ttf") format("trueType");
}

@font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular"),
    url("./fonts/roboto/RobotoRegular.ttf") format("ttf"),
    url("./fonts/roboto/RobotoRegular.ttf") format("trueType");
}

@font-face {
  font-family: "MontserratThin";
  src: local("MontserratThin"),
    url("./fonts/montserrat/MontserratThin.ttf") format("ttf"),
    url("./fonts/montserrat/MontserratThin.ttf") format("trueType");
    
}
@font-face {
  font-family: "MonserratRegular";
  src: local("MonserratRegular"),
    url("./fonts/montserrat/MontserratRegular.ttf") format("ttf"),
    url("./fonts/montserrat/MontserratRegular.ttf") format("trueType");
}
@font-face {
  font-family: "MontserratMedium";
  src: local("MontserratMedium"),
    url("./fonts/montserrat/MontserratMedium.ttf") format("ttf"),
    url("./fonts/montserrat/MontserratMedium.ttf") format("trueType");
}
@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
    url("./fonts/montserrat/MontserratBold.ttf") format("ttf"),
    url("./fonts/montserrat/MontserratBold.ttf") format("trueType");
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
