@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";
.list_sale {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title {
      font-size: 12px;
      color: #bcbcbc;
      font-weight: normal;
    }

    .plus {
      font-size: 12px;
      font-weight: 100;
      text-decoration: underline;
      color: #8884d8;
      font-style: italic;
    }
  }

  .table_list {
    .responsive-table {
      font-family: "lato", sans-serif;
      font-size: 12px;
      li {
        border-radius: 3px;
        padding: 10px 30px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;

        @include sm {
          padding: 10px 20px;
        }
      }
      .table-header {
        background-color: #95a5a661;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.03em;
      }
      .table-row {
        background-color: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }
      .col-1 {
        flex-basis: 10%;
      }
      .col-2 {
        flex-basis: 40%;

        &.Ese {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          .container-Ese {
            width: 15px;
            height: 15px;
            background-color: #607955df;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            .Ese {
              font-size: 11px;
              font-weight: normal;
            }
          }
        }
      }
      .col-3 {
        flex-basis: 25%;
      }
      .col-4 {
        flex-basis: 25%;

        .status {
          padding: 2px 5px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;

          .ico {
            font-size: 15px;
          }

          &.validate {
            font-size: 10px;
            color: $primary-green-color;
            background-color: $soft-green-color;
          }

          &.reserved {
            font-size: 10px;
            color: $primary-reserved-color;
            background-color: $soft-reserved-color;
          }

          &.pending {
            font-size: 10px;
            color: $primary-orange-color;
            background-color: $soft-orange-color;
          }

          &.canceled {
            font-size: 10px;
            color: $primary-red-color;
            background-color: $soft-red-color;
          }

          &.dette {
            font-size: 10px;
            color: $primary-notify-color;
            background-color: $soft-notify-color;
          }
        }
      }

      @media all and (max-width: 767px) {
        .table-header {
          display: none;
        }
        // .table-row{

        // }
        li {
          display: block;
        }
        .col {
          flex-basis: 100%;
        }
        .col {
          display: flex;
          padding: 10px 0;
          &:before {
            color: #6c7a89;
            padding-right: 10px;
            content: attr(data-label);
            flex-basis: 50%;
            text-align: right;
          }
        }
      }
    }
  }
}
