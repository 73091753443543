@import '../../styles/variables.scss';
@import '../../styles/responsive.scss';

.order_supplier_list_page{
    display: flex;
    flex-direction: column;
    gap: 15px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
            display: flex;
            align-items: center;
            gap: 10px;

            .ico {
                font-size: 17px;

                @include md {
                    font-size: 12px;
                }
            }

            .title {
                font-size: 18px;
                font-weight: 500;
            }


        }

        .right {
            button {
                border-radius: 4px;
                cursor: pointer;
                padding: 6px;
                font-size: 12px;
                color: $primary-black-color;
                background-color: transparent;
                border: 1px solid $secondary-black-color;
                display: flex;
                align-items: center;
                gap: 5px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;


                .ico {
                    font-size: 17px;
                }

                &:hover {
                    box-shadow: none;

                }
            }
        }
    }

    .options {
        display: flex;
        gap: 20px;
        padding: 10px;
        border-bottom: 2px solid #dfe4e1;
        width: 100%;

        .option {
            padding: 4px 10px;
            border-radius: 5px;
            cursor: pointer;

            span {
                font-size: 12px;
                color: $secondary-black-color;
                cursor: pointer;
                font-weight: bold;

                &:last-child {
                    font-weight: bold;
                    margin-left: 10px;
                }
            }

            .count {
                padding-inline: 5px;
            }

            &:hover {
                background-color: #ffffff;
            }
        }

        .option_active {
            position: relative;
            padding: 4px 10px;
            border-radius: 5px;
            box-shadow: 0px 0px 4px #e4e4e4;
            background-color: $primary-white-color;

            cursor: pointer;

            span {
                font-size: 12px;
                font-weight: bold;
                color: $primary-black-color;
                cursor: pointer;

                &:last-child {
                    font-weight: bold;
                    margin-left: 10px;
                }
            }

            .count {
                padding-inline: 5px;
            }

            .underline1 {
                margin-left: -8px;
                top: 32px;
                width: 73px;
                height: 3.4px;
                background-color: #414141;
                position: absolute;
                // animation: anim_ation .3s ease;
            }
            .underline2 {
                margin-left: -8px;
                top: 32px;
                width: 93px;
                height: 3.4px;
                background-color: #414141;
                position: absolute;
                // animation: anim_ation .3s ease;
            }
            .underline3 {
                margin-left: -8px;
                top: 32px;
                width: 80px;
                height: 3.4px;
                background-color: #414141;
                position: absolute;
                // animation: anim_ation .3s ease;
            }
            .underline4 {
                margin-left: -8px;
                top: 32px;
                width: 90px;
                height: 3.4px;
                background-color: #414141;
                position: absolute;
                // animation: anim_ation .3s ease;
            }
            .underline5 {
                margin-left: -8px;
                top: 32px;
                width: 77px;
                height: 3.4px;
                background-color: #414141;
                position: absolute;
                // animation: anim_ation .3s ease;
            }

            @keyframes anim_ation {
                0%{
                    width: 0px
                }
                100%{
                    // left:50px
                    width: 60px
                }
            }
        }
    }
}