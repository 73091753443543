
@import '../../styles/variables.scss';

.user_table {
    font-size: 12px;

    .css-177gid-MuiTableCell-root{
        padding: 8px;
    }
    .css-1ygcj2i-MuiTableCell-root, .css-1ndpvdd-MuiTableCell-root {
        font-weight: 600;
    }
    
    .editer {
        font-size: 12px;
        color:#4994d1 ;
        margin-right: 10px;
        cursor: pointer;
    }

    .supprimer {
        font-size: 12px;
        color: #6c3737;
        text-decoration: underline;
        cursor: pointer;
    }
}