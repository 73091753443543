@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.OrderSupplierAll {
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  background-color: $primary-white-color;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .header {
    width: 100%;

    .search {
      // position: absolute;
      // top: 2.4%
      width: 100%;
    }

    .btns {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;

      .status {
        padding: 2px 5px;
        border-radius: 5px;
        cursor: pointer;

        &.validate {
          font-size: 10px;
          color: $primary-green-color;
          background-color: $soft-green-color;
        }

        &.pending {
          font-size: 10px;
          color: $primary-orange-color;
          background-color: $soft-orange-color;
        }

        &.canceled {
          font-size: 10px;
          color: $primary-red-color;
          background-color: $soft-red-color;
        }
      }
    }
  }

  .table_list {
    .css-l2upbl-MuiTableCell-root,
    .css-1h37f49-MuiTableCell-root {
      font-weight: 600;

      @include lg {
        font-weight: 400;
      }
    }

    .css-dv0mq-MuiTableCell-root {
      padding: 15px;
      font-size: 12px;

      .btn {
        display: flex;
        gap: 10px;
        width: 100%;
        justify-content: flex-end;

        .status {
          padding: 2px 5px;
          border-radius: 5px;

          &.validate {
            font-size: 10px;
            color: $primary-green-color;
            background-color: $soft-green-color;
          }

          &.pending {
            font-size: 10px;
            color: $primary-orange-color;
            background-color: $soft-orange-color;
          }

          &.canceled {
            font-size: 10px;
            color: $primary-red-color;
            background-color: $soft-red-color;
          }
        }

        span {
          font-size: 11px;
          font-style: oblique;

          &:last-child {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
