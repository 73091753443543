@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.single_supplier_page {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;

      h3 {
        font-size: 14px;

        @include md {
          font-size: 12px;
        }
      }

      .ico {
        font-size: 17px;

        @include md {
          font-size: 12px;
        }
      }
    }

    .right {
      display: flex;
      gap: 10px;

      .btn_commande {
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 6px 10px;
        border: 1px solid $secondary-black-color;
        cursor: pointer;
        background-color: $secondary-green-color;
        color: $primary-white-color;
        gap: 3px;

        .ico {
          font-size: 16px;
        }
      }
      .btn_update {
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 6px 10px;
        border: 1px solid $secondary-green-color;
        cursor: pointer;
        background-color: transparent;
        color: $secondary-green-color;
        gap: 3px;

        .ico {
          font-size: 16px;
        }
      }
    }
  }

  .container {
    display: flex;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
    background-color: $primary-white-color;
    border-radius: 10px;
    position: relative;

    @include md {
      flex-direction: column;
      gap: 20px;
    }

    .left {
      flex: 1;
      position: sticky;
      align-self: flex-start;
      top: 0px;

      @include md {
        position: unset;
      }

      .top {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .box {
          display: flex;
          align-items: center;
          gap: 15px;

          .box_icon {
            width: 40px;
            height: 40px;
            background-color: #dfe4e1;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            .ico2 {
              color: $secondary-black-color;
            }
          }

          .box_info {
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: flex-start;

            .main_info {
              font-weight: bold;
            }

            .second_info {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              span {
                color: $secondary-black-color;
                font-size: 12px;
              }
            }
          }
        }

        .avatar {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 10px;
          padding-bottom: 10px;
          // border-bottom: 1px solid $secondary-black-color;

          img {
            width: 100px;
            height: 70px;
            object-fit: contain;
            background-color: $secondary-green-color;
            padding: 5px;
            border-radius: 5px;
          }

          .username {
            font-family: "lato", Arial, Helvetica, sans-serif;
            font-weight: bold;
            font-size: 14px;
            // text-decoration: underline;
            // color: skyblue;
            color: $secondary-black-color;
            // cursor: pointer;
            // font-style: italic;
          }

          .ico {
            font-size: 22px;
            color: $secondary-black-color;
            // color: skyblue;
          }

          .input_file {
            display: none;
          }

          .phone {
            font-weight: normal;
            color: $secondary-black-color;
          }
        }

        .body {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;

          .box {
            display: flex;
            align-items: center;
            gap: 15px;

            .box_icon {
              width: 40px;
              height: 40px;
              background-color: #dfe4e1;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;

              .ico2 {
                color: $secondary-black-color;
              }
            }

            .box_info {
              display: flex;
              flex-direction: column;
              gap: 5px;
              align-items: flex-start;

              .main_info {
                font-weight: bold;
              }

              .second_info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                span {
                  color: $secondary-black-color;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }

    .right {
      flex: 1;
      font-family: PoppinsRegular;
      .title {
        font-size: 14px;
        font-weight: bold;
      }

      ul {
        margin-top: 10px;

        li {
          list-style-type: none;
          position: relative;
          width: 2px;
          padding-top: 20px;
          background-color: $primary-green-color;
          cursor: pointer;
          &::after {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 50%;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $primary-green-color;
            transform: translateX(-50%);
          }

          .icons {
            width: 100%;
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          div {
            min-width: 400px;
            width: 400px;
            padding: 8px;
            background-color: #5bca2b23;
            border-radius: 0 5px 5px 0;

            @include md {
              min-width: 350px;
              width: max-content;
            }

            p {
              margin-bottom: 5px;
              font-size: 12px;
              display: flex;
              align-items: center;

              .ico {
                font-size: 18px;
              }
            }

            h4 {
              font-family: "poppins", "poppins", Verdana, sans-serif;
              font-size: 14px;
              font-weight: normal;
            }

            time {
              font-size: 10px;
            }
            .balance {
              margin-left: 20px;
              font-size: 10px;
              padding: 1px 3px;
              background-color: #eb2a2483;
              color: #f8faf7;
              border-radius: 2px;
            }
            .status {
              margin-left: 20px;
              font-size: 10px;
              padding: 1px 3px;
              background-color: #4bc41b36;
              color: #084317;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
}
