@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.table_client_all {
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  background-color: $primary-white-color;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .header {
    width: 100%;

    // .search {
    // position: absolute;
    // top: 2.4%;
    // }

    .right_ {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-left: 20px;

      .hidden {
        display: none;
      }

      @include md {
        margin-top: -95px;
        margin-left: -200px;
      }

      div {
        padding: 0 5px;
        background-color: $primary-white-color;
        border-radius: 10px;
        border: 1px solid #ccc;

        select {
          padding: 6px;
          border: none;
          outline: none;
          background-color: $primary-white-color;
          color: $secondary-black-color;
          font-size: 14px;
          border-radius: 10px;
        }
      }

      .select {
        padding: 0 5px;
        background-color: $primary-white-color;
        border-radius: 10px;
        border: 1px solid #ccc;

        select {
          padding: 6px;
          border: none;
          outline: none;
          background-color: $primary-white-color;
          color: $secondary-black-color;
          font-size: 14px;
          border-radius: 10px;
        }
      }
    }

    .btns {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;

      // @include md {
      //   display: none;
      // }

      // &.sm {
      // }

      .status {
        padding: 2px 5px;
        border-radius: 5px;

        &.validate {
          font-size: 10px;
          color: $primary-green-color;
          background-color: $soft-green-color;
        }

        &.pending {
          font-size: 10px;
          color: $primary-orange-color;
          background-color: $soft-orange-color;
        }
        &.reviewing {
          font-size: 10px;
          background-color: #94979a66;
          color: #525354f3;
        }

        &.canceled {
          font-size: 10px;
          color: $primary-red-color;
          background-color: $soft-red-color;
        }
      }
    }
  }

  // .table_list {
  //   .css-l2upbl-MuiTableCell-root,
  //   .css-1h37f49-MuiTableCell-root {
  //     font-weight: 600;
  //   }

  //   .css-dv0mq-MuiTableCell-root {
  //     padding: 15px;
  //     font-size: 12px;

  //     .btn {
  //       display: flex;
  //       gap: 10px;
  //       width: 100%;
  //       justify-content: flex-end;

  //       .status {
  //         padding: 2px 5px;
  //         border-radius: 5px;

  //         &.validate {
  //           font-size: 10px;
  //           color: $primary-green-color;
  //           background-color: $soft-green-color;
  //         }

  //         &.pending {
  //           font-size: 10px;
  //           color: $primary-orange-color;
  //           background-color: $soft-orange-color;
  //         }

  //         &.canceled {
  //           font-size: 10px;
  //           color: $primary-red-color;
  //           background-color: $soft-red-color;
  //         }
  //       }

  //       span {
  //         font-size: 11px;
  //         font-style: oblique;

  //         // &:last-child {
  //         //   // text-decoration: underline;
  //         // }
  //       }
  //     }
  //   }
  // }
}
