@import '../../styles/variables.scss';
@import '../../styles/responsive.scss';


.single_order_supplier_page {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include md {
            // flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        }

        .left {
            display: flex;
            gap: 10px;
            align-items: center;

            h3 {
                @include lg {
                    font-size: 14px;
                }
            }


            .ico {
                font-size: 17px;

                @include md {
                    font-size: 10px;
                }
            }
        }

        .right {
            display: flex;
            justify-content: flex-end;
            gap: 10px;

            
            .btn_commande {
                display: flex;
                align-items: center;
                font-size: 12px;
                padding: 6px 10px;
                border: 1px solid $secondary-black-color;
                cursor: pointer;
                background-color: $secondary-green-color;
                color: $primary-white-color;

                .ico {
                    font-size: 14px;
                }
            }
        }
    }

    .container {
        display: flex;
        padding: 20px;
        box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
        background-color: $primary-white-color;
        border-radius: 10px;
        position: relative;

        @include md {
            flex-direction: column;
            gap: 20px;
        }

        .left {
            flex: 1;
            position: sticky;
            align-self: flex-start;
            top: 0px;

            @include md {
                position: unset;
            }

            .top {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .box {
                    display: flex;
                    align-items: center;
                    gap: 15px;

                    .box_icon {
                        width: 40px;
                        height: 40px;
                        background-color: #dfe4e1;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .ico2 {
                            color: $secondary-black-color;
                        }
                    }

                    .box_info {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        align-items: flex-start;

                        .main_info {
                            font-weight: bold;
                        }

                        .second_info {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;

                            span {
                                color: $secondary-black-color;
                                font-size: 12px;

                            }
                        }
                    }

                }

                .avatar {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    gap: 5px;

                    .ico {
                        width: 50px;
                        height: 50px;
                        background-color: #dfe4e1;

                    }

                    .username {
                        font-weight: bold;
                        font-size: 18px;
                    }

                    .phone {
                        font-weight: normal;
                        color: $secondary-black-color;
                    }
                }

                .body {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;
                    margin-top: 10px;

                    .content {
                        width: 98%;
                        // background-color: rgb(242, 242, 242);
                        background-color: $soft-orange-color;
                        display: flex;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        gap: 15px;
                        padding: 20px;
                        // padding-top: 15px;
                        // padding-bottom: 15px;
                        border-radius: 5px;

                        .box {
                            display: flex;
                            align-items: center;
                            gap: 15px;
                            width: 45%;

                            &.user,
                            .reste {
                                width: 100%;
                            }

                            .box_icon {
                                width: 30px;
                                height: 30px;
                                background-color: #dfe4e1;
                                border-radius: 5px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .ico2 {
                                    color: $secondary-black-color;
                                    font-size: 18px;
                                }
                            }

                            .box_info {
                                display: flex;
                                flex-direction: column;
                                gap: 5px;
                                align-items: flex-start;

                                .main_info {
                                    font-weight: bold;
                                }

                                .second_info {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;

                                    span {
                                        color: $secondary-black-color;
                                        font-size: 12px;
                                    }
                                }
                            }

                        }
                    }

                }
            }


        }

        .right {
            flex: 1;

            .title {
                font-size: 14px;
                font-weight: bold;

            }

            ul {
                margin-top: 10px;

                li {
                    list-style-type: none;
                    position: relative;
                    width: 2px;
                    padding-top: 20px;
                    background-color: $primary-green-color;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0px;
                        left: 50%;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: $primary-green-color;
                        transform: translateX(-50%);
                    }

                    div {
                        min-width: 400px;
                        width: 400px;
                        padding: 8px;
                        background-color: #5bca2b23;
                        border-radius: 0 5px 5px 0;

                       @include md{
                        min-width: 350px;
                        width: max-content;
                      }

                        p {
                            margin-bottom: 5px;
                            font-size: 12px;
                        }

                        time {
                            font-size: 10px;
                        }
                    }
                }
            }

        }
    }
}