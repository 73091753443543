@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.add_user {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101c8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;

  .dialog {
    background-color: aliceblue;
    width: 50vw;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
    padding: 15px;

    @include md {
      width: 70vw;
    }
    @include sm {
      width: 90vw;
    }

    .header {
      // background-color: $title_bg_color;
      width: 100%;

      .title__ {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .title_left__ {
          display: flex;
          align-items: center;
          gap: 5px;

          .ico {
            font-size: 17px;
            color: $secondary-green-color;
          }
          span {
            color: $secondary-green-color;
            font-size: 15px;
          }
        }
        .ico {
          flex: 17px;
          cursor: pointer;
        }

        p {
          width: 100%;
          font-size: 12px;
          padding: 8px;
          border-radius: 5px;
          background-color: rgba(131, 32, 32, 0.192);
          margin-bottom: 5px;
          color: $secondary-black-color;
          width: max-content;
          color: red;
        }
      }
    }

    .body {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;

      .item {
        display: flex;
        flex-direction: column;
        width: 30%;
        gap: 5px;

        &.pswd {
          cursor: not-allowed;
        }

        @include sm {
          width: 45%;
        }

        &.avenue {
          width: 61%;
        }

        &.phone {
          width: 38%;
          @include sm {
            width: 98%;
          }
          @include md {
            width: 98%;
          }
        }

        &.phone input {
          padding-left: 40px;
        }

        &.country {
          width: 54%;
          @include sm {
            width: 98%;
          }
          @include md {
            width: 98%;
          }
          // height: 30px;

          // .menu-flags {
          //   // padding: 3px 10px;
          // }
          .menu-flags-button {
            padding: 3px 7px;
          }
        }
        &.orgCountry {
          width: 62%;
          // height: 30px;

          // .menu-flags {
          //   // padding: 3px 10px;
          // }
          .menu-flags-button {
            padding: 3px 7px;
          }
        }

        label {
          color: #353535;
          font-size: 12px;
        }

        input {
          height: 30px;
          width: 100%;
          border: 1px solid #999;
          background-color: transparent;
          border-radius: 1px;
          padding: 0px 20px;

          &:focus {
            outline: none;
          }
        }

        select {
          height: 30px;
          width: 100%;
          border: 1px solid #999;
          border-radius: 2px;
          background-color: transparent;
          padding: 5px 20px;
          color: $secondary-black-color;

          &:focus {
            outline: none;
          }
        }
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 20px;

      button {
        border: none;
        padding: 5px 10px;
        width: 80px;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        .circular {
          color: $primary-white-color;
        }

        &:first-child {
          border: 1px solid $secondary-black-color;
          color: $secondary-black-color;
          background-color: transparent;

          &:hover {
            border: 1px solid $primary-red-color;
            color: $primary-red-color;
          }
        }

        &:last-child {
          background-color: $secondary-green-color;
          border: 1px solid $secondary-green-color;
          color: $primary-white-color;

          &:hover {
            // background-color: transparent;
            color: #d4d4d4;
          }
        }
      }
    }
  }
}
