@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.card_three {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .card_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 7px;

    @include lg {
      gap: 12px;
      font-size: 13px;
    }
    @include md {
      font-size: 12px;
    }

    span {
      color: $secondary-black-color;
      font-size: 12px;
    }

    h1 {
      font-size: 22px;

      @include lg {
        font-size: 17px;
      }
    }
  }

  .card_icon {
    padding: 8px 10px;
    background-color: $soft-orange-color;
    border-radius: 15px;

    .ico {
      color: $primary-orange-color;
    }
  }
}
