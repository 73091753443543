.footer_app {
    position: fixed;
    width: 100%;
    bottom: 0px;
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    background-color: #fff;
    // background-color: aqua;

    span {
        &:first-child {
            font-size: 12px;
        }

        &:last-child {
            font-size: 10px;

        }
    }
}