@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.editprofil {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101c8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;

  .dialog {
    background-color: aliceblue;
    width: 60vw;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
    padding: 20px;

    @include sm {
      width: 90vw;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 5px 8px;
        align-items: center;
        justify-content: center;

        .ico {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 1px solid $secondary-green-color;
          color: $secondary-green-color;
        }

        span {
          color: $secondary-green-color;
          font-size: 15px;
        }
      }
    }

    .body {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;

      @include md {
        flex-direction: column;
      }

      input {
        width: 30%;
        height: 35px;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        border-radius: 2px;
        margin-bottom: 10px;
        padding: 0px 20px;

        @include md {
          width: 100%;
          margin-bottom: 5px;
        }

        &:focus {
          outline: none;
        }
      }
    }
    .errorMsg {
      margin-top: 10px;
      color: $primary-red-color;
      font-size: 12px;
      margin-left: 20px;
      text-align: center;
    }
    .btn {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 10px;

      button {
        border: none;
        padding: 10px;
        // border-radius: 5px;
        background-color: transparent;
        cursor: pointer;

        &:first-child {
          border: 1px solid $secondary-black-color;
          color: $secondary-black-color;
        }

        &:last-child {
          background-color: $secondary-green-color;
          border: 1px solid $secondary-green-color;
          color: $primary-white-color;

          &:hover {
            background-color: $primary-white-color;
            color: $secondary-green-color;
          }
        }
      }
    }
  }
}
