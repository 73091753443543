@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.single_entree_dialog {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101c8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;

  .dialog {
    background-color: aliceblue;
    width: 60vw;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
    margin-top: -30px;

    @include sm {
      width: 75vw;
      font-size: 12px;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $secondary-black-color;
      padding: 15px 20px;
      border-bottom: 1px solid #e3dfdf;
      color: $primary-green-color;

      @include sm {
        padding: 8px 20px;
      }

      .title {
        padding: 5px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        span {
          color: $primary-black-color;
          font-size: 15px;
        }
      }

      .right_side_title {
        display: flex;
        align-items: center;
        gap: 5px;

        .selectLabel {
          padding: 0 5px;
          // background-color: $primary-white-color;
          background-color: #e8edf2;

          border-radius: 10px;
          // border: 1px solid #ccc;

          select {
            padding: 6px;
            border: none;
            outline: none;
            // background-color: $primary-white-color;
            background-color: #e8edf2;

            // color: $secondary-black-color;
            font-size: 14px;
            border-radius: 10px;
            color: #4f7396;
          }
        }
      }

      .ico {
        font-size: 22px;
        font-weight: bold;
        color: $secondary-black-color;
        cursor: pointer;
      }
    }

    .body {
      display: flex;
      padding: 20px;

      @include md {
        flex-direction: column;
        gap: 10px;
        padding: 10px;
      }

      .user_info {
        display: flex;
        flex: 2;
        flex-direction: column;
        gap: 15px;
        padding: 5px;

        @include md {
          gap: 5px;
        }

        .box {
          display: flex;
          gap: 15px;

          .box_icon {
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            background-color: #dfe4e1;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include md {
              width: 25px;
              height: 25px;
              min-width: 25px;
              min-height: 25px;
            }

            .ico2 {
              color: $secondary-black-color;

              @include md {
                font-size: 15px;
              }
            }
          }

          .box_info {
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: flex-start;

            .main_info {
              font-weight: bold;

              @include md {
                font-size: 11px;
              }
            }

            .second_info {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              span {
                color: $secondary-black-color;
                font-size: 12px;

                @include md {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }

      .achat_info {
        flex: 3;
        flex-direction: row;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 15px;
        padding: 20px;
        border-radius: 5px;
        background-color: $soft-orange-color;

        @include md {
          gap: 5px;
          padding: 10px;
        }

        .box {
          display: flex;
          align-items: center;
          gap: 15px;
          width: 45%;

          @include sm {
            gap: 10px;
          }

          &.nature {
            width: 100%;
            border-bottom: 2px solid #d0d0d0;
            padding-bottom: 20px;

            @include sm {
              padding-bottom: 10px;
            }

            @include md {
              padding-bottom: 10px;
              margin-bottom: 5px;
            }
          }

          .box_icon {
            width: 30px;
            height: 30px;
            background-color: #dfe4e1;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include md {
              width: 30px;
              height: 30px;
              min-width: 30px;
              min-height: 30px;
            }

            .ico2 {
              color: $secondary-black-color;
              font-size: 18px;
            }
          }

          .box_info {
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: flex-start;

            .main_info {
              font-weight: bold;

              @include md {
                font-size: 11px;
              }
            }

            .second_info {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              span {
                color: $secondary-black-color;
                font-size: 12px;

                @include md {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }
  }

  .footer {
    border-radius: 0 0 15px 15px;
    padding: 10px;
    border-top: 1px solid #e3dfdf;
  }
}
