@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.notifications {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;

      .title {
        font-size: 18px;
        font-weight: 500;
      }
    }

    // .right {}
  }

  .options {
    display: flex;
    gap: 20px;
    padding: 0px;
    border-bottom: 2px solid #dfe4e1;

    .left {
      display: flex;
      gap: 20px;
      padding: 10px;
      width: 100%;
      align-items: center;

      .option {
        padding: 4px 10px;
        border-radius: 5px;
        cursor: pointer;
        z-index: 11;

        span {
          font-size: 12px;
          color: $secondary-black-color;
          cursor: pointer;
          font-weight: bold;

          &:last-child {
            font-weight: bold;
            margin-left: 10px;
          }
        }

        .count {
          padding-inline: 5px;
        }

        &:hover {
          background-color: #dfe4e1;
        }
      }

      .option_active {
        position: relative;
        padding: 4px 10px;
        border-radius: 5px;
        background-color: #ffffff;
        cursor: pointer;

        span {
          font-size: 12px;
          font-weight: bold;
          color: $secondary-black-color;
          cursor: pointer;

          &:last-child {
            font-weight: bold;
            margin-left: 10px;
          }
        }

        .count {
          padding-inline: 5px;
        }

        .underline1 {
          margin-left: -8px;
          top: 32px;
          width: 98px;
          height: 3.4px;
          background-color: #414141;
          position: absolute;
          animation: anim_ation 0.1s ease;
        }

        .underline2 {
          margin-left: -8px;
          top: 32px;
          width: 73px;
          height: 3.4px;
          background-color: #414141;
          position: absolute;
          animation: anim_ation 0.1s ease;
        }

        .underline3 {
          margin-left: -8px;
          top: 32px;
          width: 95px;
          height: 3.4px;
          background-color: #414141;
          position: absolute;
          animation: anim_ation 0.1s ease;
        }

        @keyframes anim_ation {
          0% {
            width: 0px;
          }

          100% {
            // left:50px
            width: 90px;
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 5px;

      .hidden {
        display: none;
      }

      @include md {
        margin-top: -95px;
        margin-left: -200px;
      }

      div {
        padding: 0 5px;
        background-color: $primary-white-color;
        border-radius: 10px;
        border: 1px solid #ccc;

        select {
          padding: 6px;
          border: none;
          outline: none;
          background-color: $primary-white-color;
          color: $secondary-black-color;
          font-size: 14px;
          border-radius: 10px;
        }
      }

      .select {
        padding: 0 5px;
        background-color: $primary-white-color;
        border-radius: 10px;
        border: 1px solid #ccc;

        select {
          padding: 6px;
          border: none;
          outline: none;
          background-color: $primary-white-color;
          color: $secondary-black-color;
          font-size: 14px;
          border-radius: 10px;
        }
      }
    }
  }
}
