@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.rapport_detail {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101c8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;

  .dialog {
    // background-color: aliceblue;
    background-color: white;
    width: 70vw;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
    padding: 15px;

    @include md {
      width: 95vw;
    }
    @include sm {
      width: 95vw;
    }

    .header {
      padding: 5px 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $secondary-white-color;

      span {
        color: $secondary-green-color;
        font-size: 15px;
        font-family: "PoppinsRegular";
        display: flex;
        align-items: center;
        gap: 5px;
      }

      .ico {
        font-size: 20px;
        color: $secondary-green-color;
      }
    }

    .body {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 10px;
      padding-left: 20px;

      .item {
        display: flex;
        flex-direction: column;
        width: 30%;
        gap: 5px;

        &.comment {
          width: 100%;

          label {
            color: #353535;
            font-size: 12px;
            font-weight: bold;
            text-decoration: underline;
            font-family: "PoppinsRegular";
          }

          textarea {
            // border: 1px solid #bebebe;
            padding: 10px;
            // border-radius: 5px;
            height: 80px;
            width: 100%;
            background-color: transparent;
            outline: none;
          }
        }

        .label {
          display: flex;
          align-items: center;
          gap: 5px;

          .ico {
            font-size: 18px;
          }
          label {
            color: #353535;
            font-size: 12px;
            font-weight: bold;
            text-decoration: underline;
            font-family: "PoppinsRegular";
          }
        }

        input {
          height: 30px;
          width: 100%;
          background-color: transparent;
          outline: none;
          border: none;
        }

        select {
          height: 30px;
          width: 100%;
          border: 1px solid #999;
          border-radius: 2px;
          background-color: transparent;
          padding: 5px 20px;
          color: $secondary-black-color;

          &:focus {
            outline: none;
          }
        }
      }
    }

    .btn {
      display: flex;
      justify-content: space-evenly;
      gap: 10px;
      margin-top: 20px;

      @include sm {
        width: 100%;
        flex-wrap: wrap;
      }

      button {
        display: flex;
        align-items: center;
        gap: 5px;
        border: none;
        padding: 5px 10px;
        background-color: transparent;
        cursor: pointer;

        @include sm {
          span {
            font-size: 10px;
          }
        }

        span {
          font-size: 12px;
        }
        &:first-child {
          border: 1px solid $secondary-black-color;
          color: $secondary-black-color;
          background-color: transparent;

          &:hover {
            border: 1px solid $primary-black-color;
            color: $primary-black-color;
          }
        }

        &:nth-child(2) {
          background-color: $secondary-green-color;
          border: 1px solid $secondary-green-color;
          color: $primary-white-color;

          &:hover {
            background-color: transparent;
            color: $secondary-green-color;
          }
        }
        &:nth-child(3) {
          background-color: $primary-red-color;
          border: 1px solid $primary-red-color;
          color: $primary-white-color;

          &:hover {
            background-color: transparent;
            color: $primary-red-color;
          }
        }

        &:last-child {
          background-color: $secondary-green-color;
          border: 1px solid $secondary-green-color;
          color: $primary-white-color;

          &:hover {
            background-color: transparent;
            color: $secondary-green-color;
          }
        }
      }
    }
  }
}
