@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.message_dialog {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101c8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;

  .dialog {
    background-color: aliceblue;
    width: 50vw;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
    padding: 20px;

    .errorMsg {
      font-size: 12px;
      color: red;
      padding: 10px;
    }

    @include lg {
      width: 60vw;
    }

    @include md {
      width: 70vw;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        padding: 5px 8px;
        border: 2px solid $secondary-white-color;
        display: flex;
        align-items: center;
        justify-content: center;

        .ico {
          color: $secondary-green-color;
        }

        span {
          color: $secondary-green-color;
          font-size: 15px;
        }
      }

      .Right {
        display: flex;
        gap: 10px;

        button {
          border: none;
          padding: 5px 10px;
          background-color: transparent;
          cursor: pointer;

          &:first-child {
            border: 1px solid $secondary-black-color;
            color: $secondary-black-color;
          }

          &:last-child {
            background-color: $secondary-green-color;
            border: 1px solid $secondary-green-color;
            color: $primary-white-color;

            &:hover {
              background-color: $primary-white-color;
              color: $primary-green-color;
            }
          }
        }
      }
      .Right_loading {
        display: flex;
        gap: 10px;

        button {
          border: none;
          padding: 5px 10px;
          background-color: transparent;
          width: 70px;
          cursor: pointer;

          &:first-child {
            border: 1px solid $secondary-black-color;
            color: #bab9b9;
          }

          &:last-child {
            background-color: $secondary-green-color;
            border: 1px solid $secondary-green-color;
            color: $primary-white-color;
            .circular {
              color: #fff;
              margin-bottom: -2px;
            }
          }
        }
      }
    }

    .body {
      margin-top: 10px;

      input {
        width: 100%;
        height: 40px;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        // border-radius: 5px;
        margin-bottom: 10px;
        padding: 0px 20px;

        &:focus {
          outline: none;
        }
      }

      textarea {
        width: 100%;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        // border-radius: 5px;
        padding: 20px;

        &:focus {
          outline: none;
        }
      }
    }
  }
}
