@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.order_page {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    padding-right: 10px;
    border-bottom: 2px solid #dfe4e1;

    .left {
      display: flex;
      align-items: center;
      gap: 20px;

      .cmd_btn {
        @include xxl {
          display: none;
        }
        @include md {
          display: block;
          cursor: pointer;
          padding: 2px 10px;
          font-size: 12px;
          border-radius: 4px;
          border: 1px solid #221e1ec8;
          background-color: #b7bfb7;
          color: #221e1e;
        }
      }

      .title {
        font-size: 15px;
        font-weight: 500;
        font-family: "lato", Times, serif;

        @include md {
          font-size: 14px;
          font-weight: 500;
        }
      }
      .ico {
        font-size: 17px;
        @include md {
          font-size: 12px;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 10px;

      .panel {
        position: relative;
        cursor: pointer;

        .count {
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          color: $primary-white-color;
          right: -8px;
          top: -8px;
          font-size: 10px;
          background-color: $primary-red-color;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .ico {
          color: $secondary-black-color;

          @include md {
            font-size: 18px;
          }
        }
      }
    }
  }

  .container {
    display: flex;
    gap: 20px;
    align-items: flex-start;

    .left {
      flex: 2;
      margin-top: 20px;

      .commande {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h4 {
          &:last-child {
            margin-top: 20px;
          }
        }

        .header_com {
          display: flex;
          align-items: center;
          justify-content: space-between;

          h4 {
            @include md {
              font-size: 12px;
            }
          }

          select {
            outline: none;
            padding: 10px 15px;
            border: none;
            color: $secondary-black-color;
            border: 1px solid #c1c1c1;
            border-radius: 4px;
            cursor: pointer;
            background-color: white;

            // background-color: transparent;
          }
        }

        .form_commande {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .poussin {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .type_category {
              display: flex;
              flex-direction: row;
              gap: 10px;

              .item {
                border: 1px solid #9b9a9a;
                padding: 10px;
                border-radius: 4px;
                width: 50%;
                background-color: white;

                .label {
                  margin-right: 10px;
                  font-size: 14px;
                }
                .value {
                  font-size: 14px;
                  font-weight: bold;
                  color: #787878;
                }
              }
            }

            .quantite_prixunitaire {
              display: flex;
              flex-direction: row;
              gap: 10px;
            }

            .total_reste {
              background-color: $soft-green-color;
              padding: 20px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              gap: 10px;
              border-radius: 5px;

              .errorMsg {
                display: flex;
                align-items: center;
                font-family: MonserratRegular;
                font-size: 12px;
                color: #de2424;
                gap: 5px;
                .icon {
                  color: #de2424;
                  font-size: 16px;
                }
              }
            }
          }

          .poulet {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 10px;

            .type_category {
              display: flex;
              flex-direction: row;
              gap: 10px;
            }

            .quantite_prixunitaire {
              display: flex;
              flex-direction: row;
              gap: 10px;
            }

            .total_reste {
              background-color: $soft-green-color;
              padding: 20px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              gap: 10px;
              border-radius: 5px;
            }
          }

          .aliment {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 10px;

            .type_category {
              display: flex;
              flex-direction: row;
              gap: 10px;
            }

            .quantite_prixunitaire {
              display: flex;
              flex-direction: row;
              gap: 10px;
            }

            .total_reste {
              background-color: $soft-green-color;
              padding: 20px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              gap: 10px;
              border-radius: 5px;
            }
          }

          .biogaz {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 10px;

            .type_category {
              display: flex;
              flex-direction: row;
              gap: 10px;
            }

            .quantite_prixunitaire {
              display: flex;
              flex-direction: row;
              gap: 10px;
            }

            .total_reste {
              background-color: $soft-green-color;
              padding: 20px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              gap: 10px;
              border-radius: 5px;
            }
          }

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $primary-white-color;
            gap: 10px;
            border: none;
            padding: 5px;
            border-radius: 4px;

            &.activated {
              background-color: $primary-green-color;
              cursor: pointer;
            }

            &.disabled {
              background-color: #bbc4bd;
              cursor: not-allowed;
            }
          }
        }

        // .search {
        //     // padding-left: 20px;
        //     // position: absolute;
        //     // top: 2.4%
        //     background-color:#221e1e;
        // }

        .form_user {
          display: flex;
          flex-direction: column;
          gap: 10px;
          background-color: #e8edf2;

          padding: 5px;

          .user_input {
            display: flex;
            gap: 20px;
            padding: 10px;
            justify-content: space-between;
            .item {
              display: flex;
              flex-direction: column;
              .label {
                display: flex;
                align-items: center;
                font-family: MonserratRegular;
                gap: 5px;
                .ico {
                  color: #4f7396;
                  font-size: 55px;
                }

                .info {
                  display: flex;
                  flex-direction: column;

                  .headerrr {
                    color: #4f7396;
                    font-size: 14px;
                  }
                  span {
                    color: #050505;
                    font-size: 14px;
                    margin-top: 5px;
                    // text-decoration: underline;
                  }
                }
              }
              .value {
                font-family: "lato", Arial, sans-serif;
                font-size: 13px;
                margin-top: 5px;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
