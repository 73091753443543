@import "../../../styles/variables.scss";

.responsive-table {
  font-family: "lato", sans-serif;
  font-size: 12px;
  li {
    border-radius: 3px;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    align-items: center;
  }
  .table-header {
    background-color: #95a5a6;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }
  .table-row {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .col-1 {
    flex-basis: 10%;
  }
  .col-2 {
    flex-basis: 40%;
  }
  .col-3 {
    flex-basis: 25%;
    align-items: flex-start;

    .img_cat {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
    }

    .status {
      padding: 2px 5px;
      border-radius: 5px;
      text-align: center;
      // width: fit-content;

      &.validate {
        font-size: 10px;
        color: $primary-green-color;
        background-color: $soft-green-color;
      }

      &.pending {
        font-size: 10px;
        color: $primary-orange-color;
        background-color: $soft-orange-color;
      }
      &.reviewing {
        font-size: 10px;
        background-color: #94979a66;
        color: #525354f3;
      }

      &.canceled {
        font-size: 10px;
        color: $primary-red-color;
        background-color: $soft-red-color;
      }
    }
  }
  .col-4 {
    flex-basis: 25%;
  }

  @media all and (max-width: 767px) {
    .table-header {
      display: none;
    }
    // .table-row{

    // }
    li {
      display: block;
    }
    .col {
      flex-basis: 100%;
      .img_cat {
        display: none;
      }
    }
    .col {
      display: flex;
      padding: 10px 0;
      &:before {
        color: #6c7a89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 50%;
        text-align: right;
      }
    }
  }
}
