@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.dashboard {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .header {
    span {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .home {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(120px, auto);

    .box {
      padding: 20px;
      box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
      background-color: $primary-white-color;
      border-radius: 10px;

      @include lg {
        padding: 15px;
      }
    }

    .box1,
    .box2,
    .box3,
    .box4 {
      @include md {
        grid-column: span 2;
      }
      @include sm {
        grid-column: span 2;
      }
    }

    .box_graph1 {
      grid-column: span 3;
      grid-row: span 2;

      // @include lg {
      //   grid-column: span 4;
      // }
      // @include sm {
      //   display: none;
      //   background-color: aqua;
      // }
      @include md {
        display: none;
      }
      @include lg {
        grid-column: span 4;
      }
    }
    .box_graph2 {
      grid-column: span 1;
      grid-row: span 2;
      @include lg {
        display: none;
      }
    }
    .box_list {
      grid-column: span 4;
      grid-row: span 2;
    }
  }
}
