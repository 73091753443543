@import '../../styles/variables.scss';

.conatiner_seachUser {
    display: flex;
    flex-direction: column;
    position: relative;

    .Searchbar {
        background-color: #c9d4dac9;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 6px;
        border-radius: 10px;

        &:hover {
            background-color: #d8e0e4c9;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 6px;
            border-radius: 10px;
            box-shadow: rgba(211, 210, 210, 0.16) 1px 1px 1px 2px;
        }

        .icon {
            color: #4F7396;
            font-size: 18px;
        }

        input {
            width: 100%;
            border: none;
            padding-left: 5px;
            font-size: 14px;
            color: #4F7396;
            background-color: transparent;

            &:focus {
                outline: none;
            }

            &::placeholder {
                font-size: 12px;
                color: #4F7396;
            }
        }
    }

    .contentView {
        max-height: 200px;
        // min-height: 200px;
        margin-top: 40px;
        padding: 10px;
        position: absolute;
        width: 100%;
        box-shadow: rgba(211, 210, 210, 0.16) 1px 1px 1px 2px;
        background-color: #c9d4daee;
        border-radius: 0 0 10px 10px;
        display: block;
        overflow-y: auto;

        ul {
            list-style: none;
            padding: 10px;
            li {
                border-bottom: 1px solid #999;
                color: $secondary-black-color;
                font-size: 12px;
                padding: 10px;
                cursor: pointer;

                &:hover{
                background-color: #c9d4dae1;
                border-radius: 8px;

                }

                &:last-child{
                    border-bottom: none;
                }

                .user_info{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    span{
                        &:first-child{
                            color:#4F7396
                        }
                    }
                }
            }
        }
    }
    .contentHide {
        max-height: 200px;
        // min-height: 200px;
        margin-top: 40px;
        padding: 10px;
        position: absolute;
        width: 100%;
        box-shadow: rgba(211, 210, 210, 0.16) 1px 1px 1px 2px;
        background-color: #c9d4daee;
        border-radius: 0 0 10px 10px;
        display: none;
        overflow-y: auto;

        ul {
            list-style: none;
            padding: 10px;
            li {
                border-bottom: 1px solid #999;
                color: $secondary-black-color;
                font-size: 12px;
                padding: 10px;
                cursor: pointer;

                &:last-child{
                    border-bottom: none;
                }

                .user_info{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    span{
                        &:first-child{
                            color:#4F7396
                        }
                    }
                }
            }
        }
    }
}