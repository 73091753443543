@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.message_dialog {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101c8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  .dialog {
    background-color: aliceblue;
    width: 50vw;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
    padding: 20px 20px 10px 20px;
    min-height: 200px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        color: $primary-black-color;
        font-size: 14px;

        @include sm {
          font-size: 12px;
        }
      }

      .header-left {
        display: flex;
        align-items: center;
        gap: 5px;

        .ico {
          font-size: 17px;
        }
      }

      .ico {
        font-size: 15px;
        cursor: pointer;
      }
    }

    .body_detail {
      font-size: 12px;
      min-height: 150px;

      .title {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border-bottom: 1px solid #bcbaba;
        padding-bottom: 5px;
        margin-top: 20px;

        @include sm {
          flex-direction: column;
          font-size: 11px;
        }

        .title_right {
          display: flex;
          align-items: center;
          gap: 10px;

          .ico {
            font-size: 25px;
            cursor: pointer;
            color: #0ea04b;
          }
          span {
            font-weight: 300;
            font-style: italic;
            color: #777;

            @include sm {
              font-size: 11px;
            }
          }
        }
      }
      .container {
        margin-top: 10px;
        font-size: 12px;
        background-color: rgba(92, 205, 124, 0.121);
        height: 100%;
        padding: 10px;
        min-height: 130px;
      }
    }

    .errorMsg {
      font-size: 11px;
      color: red;
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;

      .footer_left {
        display: flex;
        align-items: center;
        gap: 10px;
        .deleteBtn {
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;

          .sup {
            font-size: 11px;
            color: rgba(255, 0, 0, 0.548);
            text-decoration: underline;
          }
          .ico {
            font-size: 30px;
            color: #1e212377;
            cursor: pointer;
          }
        }
        .addImage {
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;

          .add {
            font-size: 11px;
            color: #158ed077;
            text-decoration: underline;
          }

          .ico {
            font-size: 25px;
            color: #1e212377;
            cursor: pointer;
          }
        }
        .updateImage {
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;

          .update {
            font-size: 11px;
            color: #23262877;
            text-decoration: underline;
          }

          .ico {
            font-size: 25px;
            color: #1e212377;
            cursor: pointer;
          }
        }

        .image_btn {
          display: flex;
          align-items: center;
          gap: 10px;

          .cancel {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 2px 6px;
            cursor: pointer;
            border: 1px solid $primary-black-color;
            color: $primary-black-color;
            background-color: transparent;

            &:hover {
              border: 1px solid $secondary-black-color;
              color: $secondary-black-color;
            }
            .ico {
              font-size: 16px;
              font-weight: 700;
            }
            span {
              font-size: 13px;
              font-weight: lighter;
            }
          }
          .update {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 2px 6px;
            cursor: pointer;
            background-color: $secondary-green-color;
            border: 1px solid $secondary-green-color;
            color: $primary-white-color;

            &:hover {
              background-color: transparent;
              color: $secondary-green-color;
            }

            .ico {
              font-size: 16px;
              font-weight: 700;
            }
            span {
              font-size: 13px;
              font-weight: lighter;
            }
          }
        }
      }

      .moment {
        font-size: 12px;
        color: $primary-green-color;
        font-weight: normal;
        font-style: italic;
      }
    }
  }
}
