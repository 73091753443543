@import "../../styles/variables.scss";

.success_cmd_dialog {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #010101c8;
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 9999;

  .dialog {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 40vw;
    height: 40vh;
    background-color: $primary-white-color;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    border-radius: 10px;

    .header {
      display: flex;
      justify-content: flex-end;

      .ico {
        font-size: 22px;
        color: $secondary-black-color;
        cursor: pointer;
      }
    }

    .body {
      margin-top: -20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: 100%;
      align-items: center;
      justify-content: center;

      div {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: rgba(67, 158, 88, 0.9);
        // position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          // position: absolute;
          top: 2px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: $primary-white-color;

          .ico {
            width: 30px;
            height: 30px;
            color: $primary-black-color;
            font-weight: bold;
            background-color: rgba(67, 158, 88, 0.537);
            border-radius: 50%;
            padding: 5px;
          }
        }
      }
      font-weight: bold;
    }
  }
}
