@import '../../styles/variables.scss';

.order_card_dialog {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101c8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;

  .dialog {
    background-color: aliceblue;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: relative;
    border-radius: 10px;
   
    .header {
      position: sticky;
      top: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color:aliceblue ;
      padding: 10px;
      border-radius: 15px 15px 0 0;

      .title {
        padding: 5px 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          color: $secondary-black-color;
          font-size: 15px;
        }
      }
    }

    .body {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0px 20px;
      width: 70vw;
      max-height: 60vh;
      overflow-y: auto;

      .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        gap: 5px;
        background-color: #5bca2b23;
        border-radius: 5px;

        .content {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .box_info {
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: flex-start;

            .main_info {
              font-weight: 300;
              font-size: 11px;
            }

            .second_info {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              gap: 10px;

              span {
                color: $secondary-black-color;
                font-size: 12px;
                border-left: 1px solid $secondary-black-color;
                padding-left: 5px;
              }
            }
          }
        }
        .user_paiement {
          display: flex;
          flex-direction: column;
          gap: 5px;
          align-items: flex-start;

          .main_info {
            font-weight: 300;
            font-size: 11px;
          }

          .second_info {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 10px;

            span {
              color: $secondary-black-color;
              font-size: 12px;
              border-left: 1px solid $secondary-black-color;
              padding-left: 5px;
            }
          }
        }
        img {
          width: 80px;
          height: 80px;
          object-fit: cover;
        }

        .ico {
          font-size: 25px;
          color: $secondary-black-color;
          
          &:hover{
            color: $primary-red-color;
          }

        }
      }
    }

    .btn {
      position: sticky;
      bottom: 0px;
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 20px;
      padding: 10px;
      border-top: 1px solid #ccc;
      background-color: aliceblue;
      border-radius: 0 0 15px 15px ;


      button {
        border: none;
        padding: 5px 10px;
        border-radius: 5px;
        background-color: transparent;
        cursor: pointer;

        &:first-child {
          border: 1px solid $secondary-black-color;
          color: $secondary-black-color;
          background-color: transparent;

          &:hover {
            border: 1px solid $primary-red-color;
            color: $primary-red-color;
          }
        }

        &:last-child {
          background-color: $secondary-green-color;
          border: 1px solid $secondary-green-color;
          color: $primary-white-color;

          &:hover {
            background-color: transparent;
            color: $secondary-green-color;
          }
        }
      }
    }
  }
}