@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.oeuf_card {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      // font-weight: bold;
      font-size: 12px;

      @include md {
        font-size: 12px;
      }
    }

    .number {
      display: flex;
      align-items: center;

      span {
        &:first-child {
          font-weight: bold;
          font-size: 22px;

          @include md {
            font-size: 16px;
          }
        }

        &:last-child {
          padding-top: 7px;
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
  }

  .items_sk {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    margin-top: 10px;
  }
  .items {
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-top: 10px;
      .item_header {
        display: flex;
        justify-content: space-between;
        font-family: "lato", Arial, Helvetica, sans-serif;
        font-weight: 300;

        .title {
          font-size: 12px;
        }
        .count {
          font-size: 12px;
        }
      }
      // .item_progressbar {
      // }
    }
  }
}
