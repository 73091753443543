@import "../../../styles/responsive.scss";

.dialog_list_ {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101c8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;

  .content {
    width: 75%;
    height: 65%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    padding-bottom: 15px;

    .titleHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background-color: #ffffff;
      padding: 10px;
      border-radius: 10px 10px 0px 0px;
      border-bottom: 2px solid #6c7a89;
      span {
        font-family: "lato", sans-serif;
        font-size: 14px;
        font-weight: bold;
      }

      .titleHeader_left {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .closeBtn {
        top: 22px;
        right: 0px;
        width: 25px;
        height: 25px;
        // border-radius: 5px;
        border: 1px solid #353737;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 30;
        cursor: pointer;

        .ico {
          color: #353737;
          font-size: 17px;
        }

        &:hover {
          border: 1px solid #353737;
        }
      }
    }
    .responsive-table {
      font-family: "lato", sans-serif;
      font-size: 12px;
      width: 100%;
      min-height: 60%;
      height: max-content;
      padding: 10px;
      background-color: #ffffff;
      overflow-y: auto;

      li {
        border-radius: 3px;
        padding: 10px 30px;
        display: flex;
        justify-content: space-between;

        @include sm {
          padding: 10px 20px;
        }

        // &.body {
        //   margin-top: 45px;
        // }
      }
      .table-header {
        background-color: #95a5a6;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        // overflow: hidden;
        // position: fixed;
        // margin-bottom: 30px;
        // background-color: #333;
        // top: 0;
        // width: 67%;
      }

      .table-row {
        background-color: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }
      .col-1 {
        flex-basis: 10%;
      }
      .col-2 {
        flex-basis: 40%;
        &.Ese {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          .container-Ese {
            width: 15px;
            height: 15px;
            background-color: #607955df;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            .Ese {
              font-size: 11px;
              font-weight: normal;
            }
          }
        }
      }
      .col-3 {
        flex-basis: 25%;
      }
      .col-4 {
        flex-basis: 25%;

        span {
          padding: 2px 10px;
          background-color: #e038223b;
          color: #3f0901;
          font-weight: 700;
          border-radius: 3px;
          font-size: 12px;
        }
      }

      @media all and (max-width: 767px) {
        .table-header {
          display: none;
        }
        // .table-row{

        // }
        li {
          display: block;
        }
        .col {
          flex-basis: 100%;
        }
        .col {
          display: flex;
          padding: 10px 0;
          &:before {
            color: #6c7a89;
            padding-right: 10px;
            content: attr(data-label);
            flex-basis: 50%;
            text-align: right;
          }
        }
      }
    }
  }
}
