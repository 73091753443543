@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.edit_user {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101c8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;

  .dialog {
    background-color: aliceblue;
    width: 60vw;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
    padding: 15px;
    overflow-y: scroll;

    @include sm {
      width: 95vw;
      padding: 5px;
      overflow-y: scroll;
    }

    @include md {
      width: 80vw;
    }

    @include lg {
      width: 70vw;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title_ {
        display: flex;
        padding: 5px 8px;
        align-items: center;
        width: 100%;
        gap: 5px;

        @include md {
          gap: 10px;
        }
        // justify-content: center;
        .ico {
          font-size: 17px;
          color: $secondary-green-color;
        }
        span {
          color: $secondary-green-color;
          font-size: 14px;
        }
      }
    }

    .body {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;

      &:first-child {
        padding-bottom: 20px;
        border-bottom: 1px solid #999;
      }

      .item {
        display: flex;
        flex-direction: column;
        width: 30%;
        gap: 5px;

        @include md {
          width: 48%;
        }
        @include sm {
          width: 48%;
        }

        &.disabled {
          input {
            height: 30px;
            width: 100%;
            border: 1px solid #6d6b6b;
            background-color: #85f5923d;
            border-radius: 1px;
            padding: 0px 20px;

            cursor: not-allowed;
          }
        }

        label {
          color: #353535;
          font-size: 12px;
        }

        input {
          height: 30px;
          width: 100%;
          border: 1px solid #999;
          background-color: transparent;
          border-radius: 1px;
          padding: 0px 20px;

          &:focus {
            outline: none;
          }
        }

        select {
          height: 30px;
          width: 100%;
          border: 1px solid #999;
          border-radius: 2px;
          background-color: transparent;
          padding: 5px 20px;
          color: $secondary-black-color;

          &:focus {
            outline: none;
          }
        }
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 20px;

      button {
        border: none;
        padding: 5px 10px;
        // border-radius: 5px;
        background-color: transparent;
        cursor: pointer;

        &:first-child {
          border: 1px solid $secondary-black-color;
          color: $secondary-black-color;
          background-color: transparent;

          &:hover {
            border: 1px solid $primary-red-color;
            color: $primary-red-color;
          }
        }

        &:last-child {
          background-color: $secondary-green-color;
          border: 1px solid $secondary-green-color;
          color: $primary-white-color;
          width: 70px;

          &:hover {
            background-color: transparent;
            color: $secondary-green-color;
          }
        }
      }
    }
  }
}
