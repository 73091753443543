@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.delete_dial_box {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101c8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  .dialog {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 30vw;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    padding: 15px;
    height: fit-content;

    @include lg {
      width: 40vw;
    }
    @include md {
      width: 60vw;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: $primary-black-color;
        font-size: 16px;
      }

      .ico {
        font-size: 15px;
        cursor: pointer;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      p {
        padding: 10px;
      }

      .del_Btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 10px;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 90px;
          height: 25px;

          &:hover {
            cursor: pointer;
          }

          &:first-child {
            padding: 2px 5px;
          }

          &:last-child {
            padding: 2px 5px;
          }
        }
      }
    }
  }
}
