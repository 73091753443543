@import "../../styles/variables.scss";

.profil {
  display: flex;
  flex-direction: column;
  background-color: $primary-white-color;
  padding: 20px 50px;
  border-radius: 15px;
  gap: 20px;
  height: 100%;

  .header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    .ico {
      width: 70px;
      height: 70px;
      color: $secondary-black-color;
      border: 1px solid $secondary-black-color;
      border-radius: 50%;
      background-color: white;
    }

    .username {
      font-weight: bold;
      font-size: 18px;
    }

    .email {
      font-weight: normal;
      color: $secondary-black-color;
    }

    button {
      width: fit-content;
      display: inline-block;
      border: none;
      cursor: pointer;
      padding: 5px 8px;
      font-weight: bold;
      border-radius: 5px;
      color: $primary-white-color;
      background-color: $secondary-green-color;

      &:hover {
        background-color: $primary-green-color;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    .box {
      display: flex;
      align-items: center;
      gap: 15px;

      .box_icon {
        width: 40px;
        height: 40px;
        background-color: #dfe4e1;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        .ico2 {
          color: $secondary-black-color;
        }
      }

      .box_info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;

        .main_info {
          font-weight: bold;
        }

        .second_info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 2px;

          span {
            color: $secondary-black-color;
            font-size: 12px;

            .ico {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .body_sk {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    .box {
      display: flex;
      align-items: center;
      gap: 15px;

      .box_icon {
        width: 40px;
        height: 40px;
        background-color: #dfe4e1;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        .ico2 {
          color: $secondary-black-color;
        }
      }

      .box_info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;

        .main_info {
          font-weight: bold;
        }

        .second_info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 2px;

          span {
            color: $secondary-black-color;
            font-size: 12px;
          }
        }
      }
    }
  }
}
