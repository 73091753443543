@import '../../../styles/variables.scss';
@import '../../../styles/responsive.scss';

.reserver_cf_ {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101c8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;

  .dialog {
    background-color: aliceblue;
    width: 50vw;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
    // padding: 20px;

    @include md{
      width: 80vw;
    }

    .header {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      border-bottom: 1px solid $secondary-black-color;
      padding: 15px 20px;
      border-bottom: 1px solid #e3dfdf;

      .title {
        padding: 5px 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          color: $primary-black-color;
          font-size: 15px;

          @include md{
            font-size: 14px;
          }
        }
      }

      .search{
        width: 50%;
      }

      .ico {
        font-size: 22px;
        font-weight: bold;
        color: $secondary-black-color;
        cursor: pointer;
      }
    }

    .body {
      // margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      padding: 0 16px;
      max-height: 400px;
      overflow-y: auto;
      background-color: $primary-white-color;
      padding: 10px 0px;

      .itemTitle {
        width: 90%;

        span {
          font-size: 16px;
        }
      }

      .item {
        display: flex;
        flex-direction: column;
        width: 30%;
        gap: 5px;

        &.libele {
          width: 60%;
          input{
            padding: 5px;
          }
        }
        &.deadline {
          width: 30%;
        }
        &.produit_info {
          width: 95%;
          font-size: 14px;
          padding: 10px;
          border-bottom:1px solid #999;
        }
        &.user_info {
          width: 95%;
          font-size: 14px;
          margin-top: 20px;
          padding: 10px;
          border-bottom:1px solid #999;
        }

        &.detail {
          width: 92%;

          textarea {
            width: 100%;
            padding: 5px;
            outline:none;
          }

        }

        label {
          color: #353535;
          font-size: 12px;


        }

        input {
          height: 30px;
          width: 100%;
          border: 1px solid #999;
          background-color: transparent;
          border-radius: 1px;
          padding: 0px 20px;

          &:focus {
            outline: none;
          }
        }

        select {
          height: 30px;
          width: 100%;
          border: 1px solid #999;
          border-radius: 2px;
          background-color: transparent;
          padding: 5px 20px;
          color: $secondary-black-color;

          &:focus {
            outline: none;
          }

        }
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      gap: 10px;
      border-radius: 0 0 15px 15px;
      padding: 15px;
      border-top: 1px solid #e3dfdf;

      button {
        border: none;
        padding: 5px 15px;
        // border-radius: 5px;
        background-color: transparent;
        cursor: pointer;

        &:first-child {
          border: 1px solid $secondary-black-color;
          color: $secondary-black-color;
          background-color: transparent;

          &:hover {
            border: 1px solid $primary-red-color;
            color: $primary-red-color;
          }
        }

        &:last-child {
          background-color: $secondary-green-color;
          border: 1px solid $secondary-green-color;
          color: $primary-white-color;

          &:hover {
            background-color: transparent;
            color: $secondary-green-color;
          }
        }
      }
    }
  }
}