@import "../../styles/variables.scss";

.warning_cmd_dialog {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #010101c8;
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 9999;

  .dialog {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 40vw;
    height: 40vh;
    background-color: $primary-white-color;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px 20px;
    border-radius: 10px;

    // .header {
    //   display: flex;
    //   justify-content: flex-end;

    //   .close_btn {
    //     width: 30px;
    //     height: 30px;
    //     border: 1px solid #333;
    //     cursor: pointer;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;

    //     .ico {
    //       font-size: 22px;
    //       color: $secondary-black-color;
    //       cursor: pointer;
    //     }
    //   }
    // }

    .body {
      margin-top: -20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: 100%;
      align-items: center;
      justify-content: center;

      .ico {
        width: 65px;
        height: 65px;
        color: $primary-black-color;
        font-weight: bold;
        color: #236dc189;
        border-radius: 50%;
        padding: 5px;
      }
      .btn {
        display: flex;
        gap: 5px;
        align-items: center;

        button {
          padding: 5px 8px;
          cursor: pointer;
        }
      }
    }
    p {
      position: absolute;
      font-style: italic;
      text-decoration: underline;
      cursor: pointer;
      font-size: 12px;
      bottom: 10px;
      right: 10px;
      color: #236dc189;
    }
  }
}
