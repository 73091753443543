@import "../../styles/variables.scss";
.Searchbar {
  background-color: #e8edf2;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 10px;

  &:hover {
    background-color: #e8edf2;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: 10px;
    box-shadow: rgba(211, 210, 210, 0.16) 1px 1px 1px 2px;
  }

  .icon {
    color: #4f7396;
    font-size: 18px;
  }

  input {
    width: 100%;
    border: none;
    padding-left: 5px;
    font-size: 14px;
    color: #4f7396;
    background-color: transparent;

    &:focus {
      outline: none;
    }
    &::placeholder {
      font-size: 12px;
      color: #4f7396;
    }
  }
}
