.connexion_error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
  height: 300px;
  font-family: MonserratRegular;
  p {
    font-size: 14px;
    color: #444;
  }

  .ico {
    font-size: 70px;
    color: #2a781647;
  }

  button {
    border: 1px solid #444;
    padding: 5px 10px;
    background-color: transparent;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      border: 1px solid green;
      color: green;
    }
  }
}
