@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.messages {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;

      @include md {
        width: 100%;
        justify-content: space-between;
      }

      // .title {
      //   font-size: 18px;
      //   font-weight: 500;
      // }

      .btn {
        display: flex;
        align-items: center;
        gap: 3px;
        border: 1px solid $secondary-black-color;
        padding: 2px 4px;
        cursor: pointer;
        border-radius: 2px;
        gap: 5px;

        span {
          font-size: 12px;
          color: $secondary-black-color;

          @include sm {
            display: none;
          }
        }

        .icon {
          font-size: 12px;
          color: $primary-black-color;
          font-weight: bolder;
        }
      }
    }

    .right {
      display: flex;
      justify-content: flex-end;
      width: 50%;

      @include md {
        display: none;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .box_msg {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $primary-white-color;
      border: 1px solid #dbdce2;
      border-radius: 10px;
      padding: 20px;
      width: 100%;

      &:hover {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        cursor: pointer;
      }

      .left {
        display: flex;
        align-items: center;
        gap: 5px;

        input {
          width: 18px;
          height: 18px;
          border: 1px solid #dbdce2;
          cursor: pointer;
        }

        .message {
          display: flex;
          align-items: center;
          gap: 10px;

          .sender {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 12px;
            font-weight: 600;
            color: $secondary-black-color;
            overflow: hidden;

            @include lg {
              display: none;
            }
            @include md {
              display: none;
            }

            .avatar {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 40px;
              min-height: 40px;
              border-radius: 20px;
              background-color: rgba(142, 186, 169, 0.701);

              span {
                font-size: 20px;
                font-weight: bolder;
                font-family: PoppinsRegular;
                color: rgba(106, 110, 109, 0.801);
              }
            }

            &.small {
              font-size: 12px;
              font-weight: 600;
              color: $secondary-black-color;

              @include xxl {
                display: none;
              }

              @include lg {
                display: flex;
                align-items: center;
              }
              @include md {
                display: flex;
                align-items: center;
              }
            }
          }

          .text {
            font-size: 12px;
            .date {
              color: #3a5871de;
              font-style: italic;
            }
            .attach {
              font-weight: bold;
              color: rgb(204, 215, 209);
              font-size: 10px;
              padding: 1px 2px;
              background-color: rgb(31, 51, 44);
              margin-left: 10px;
            }

            @include md {
              display: none;
            }
          }
        }
      }

      .icons {
        display: flex;
        align-items: center;
        gap: 5px;

        .number {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $primary-red-color;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          icon {
            position: absolute;
            color: $primary-white-color;
            font-size: 12px;
          }
        }

        .icon {
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
  }
}
