@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.entree_page {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;

      .ico {
        font-size: 17px;
      }

      @include sm {
        font-size: 12px;
      }
    }

    .right {
      display: flex;
      gap: 10px;

      .btn_commande {
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 6px 10px;
        border: 1px solid $secondary-black-color;
        cursor: pointer;
        background-color: $secondary-green-color;
        color: $primary-white-color;

        .ico {
          font-size: 14px;
        }
      }
      button {
        border-radius: 4px;
        cursor: pointer;
        padding: 6px;
        font-size: 12px;
        color: $primary-black-color;
        background-color: transparent;
        border: 1px solid $secondary-black-color;
        display: flex;
        align-items: center;
        gap: 5px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        .ico {
          font-size: 17px;
        }

        &:hover {
          box-shadow: none;
        }

        @include sm {
          display: none;
        }
      }
      .ico_sm {
        color: $primary-green-color;
        padding: 2px;

        @include xxl {
          display: none;
        }
        @include sm {
          display: block;
        }
      }
    }
  }

  .container {
    display: flex;
    gap: 20px;
    align-items: flex-start;

    @include xl {
      gap: 10px;
    }

    .left {
      flex: 2;
      display: flex;
      flex-direction: column;

      .top {
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btns {
          display: flex;
          align-items: center;
          gap: 5px;

          button {
            border: 1px solid #ccc;
            padding: 6px;
            font-size: 12px;
            border-radius: 5px;
            color: $secondary-black-color;
            background-color: $primary-white-color;
            box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
            cursor: pointer;

            &.btn_active {
              background-color: $secondary-black-color;
              color: $primary-white-color;
            }

            @include sm {
              padding: 4px;
              font-size: 10px;

              &.btn_active {
                background-color: rgb(72, 99, 83);
                color: $primary-white-color;
              }
            }
          }
        }

        .tri {
          display: flex;
          align-items: center;
          gap: 5px;

          div {
            padding: 0 5px;
            background-color: $primary-white-color;
            border-radius: 10px;
            border: 1px solid #ccc;

            select {
              padding: 6px;
              border: none;
              outline: none;
              background-color: $primary-white-color;
              color: $secondary-black-color;
              font-size: 14px;
              border-radius: 10px;

              @include sm {
                padding: 2px;
                font-size: 12px;
              }
            }

            input {
              padding: 5px 6px;
              border: none;
              outline: none;
              background-color: $primary-white-color;
              color: $secondary-black-color;
              font-size: 14px;
              border-radius: 10px;
            }
          }
        }
      }

      .content {
        padding: 20px;
        box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
        background-color: $primary-white-color;
        border-radius: 10px;
      }
    }

    .right {
      flex: 1;
      padding: 20px;
      box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
      background-color: $primary-white-color;
      border-radius: 10px;
      height: max-content;
      position: sticky;
      top: 0;

      @include md {
        display: none;
      }
    }
  }
}
