@import '../../styles/variables.scss';

.reservation_cf {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
            display: flex;
            align-items: center;
            gap: 10px;

            .title {
                font-size: 18px;
                font-weight: 500;
            }
        }

        .right {
            display: flex;
            align-items: center;
            gap: 10px;

            button {
                border-radius: 4px;
                cursor: pointer;
                padding: 6px;
                font-size: 12px;
                color: $primary-black-color;
                background-color: transparent;
                border: 1px solid $secondary-black-color;
                display: flex;
                align-items: center;
                gap: 5px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;


                .ico {
                    font-size: 17px;
                }

                &:hover {
                    box-shadow: none;

                }
            }

            .btn_res {
                display: flex;
                align-items: center;
                background-color: rgb(17, 192, 133);
                border-radius: 3px;
                padding: 5px 15px 5px 5px;
                gap: 5px;

                .ico {
                    color: $primary-white-color;
                    font-size: 20px;
                    font-weight: bold;
                }

                span {
                    color: $primary-white-color;
                    border: none;
                    font-weight: normal;
                    background-color: transparent;
                    cursor: pointer;

                }
            }
        }

    }

    .options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid #dfe4e1;


        .left {
            display: flex;
            gap: 20px;
            padding: 10px;
            width: 100%;
            align-items: center;

            .option {
                padding: 8px 10px;
                border-radius: 5px;
                cursor: pointer;

                span {
                    font-size: 12px;
                    color: $secondary-black-color;
                    cursor: pointer;
                    font-weight: bold;

                    &:last-child {
                        font-weight: bold;
                        margin-left: 10px;
                    }
                }

                .count {
                    padding-inline: 5px;
                }

                &:hover {
                    background-color: #ffffff;
                }
            }

            .option_active {
                position: relative;
                padding: 4px 10px;
                border-radius: 5px;
                box-shadow: 0px 0px 4px #e4e4e4;
                background-color: $primary-white-color;

                cursor: pointer;

                span {
                    font-size: 12px;
                    font-weight: bold;
                    color: $primary-black-color;
                    cursor: pointer;

                    &:last-child {
                        font-weight: bold;
                        margin-left: 10px;
                    }
                }

                .count {
                    padding-inline: 5px;
                }

                .underline1 {
                    margin-left: -8px;
                    top: 36px;
                    width: 98px;
                    height: 3.4px;
                    background-color: #414141;
                    position: absolute;
                    // animation: anim_ation .3s ease;
                }

                .underline2 {
                    margin-left: -8px;
                    top: 36px;
                    width: 80px;
                    height: 3.4px;
                    background-color: #414141;
                    position: absolute;
                    // animation: anim_ation .3s ease;
                }

                .underline3 {
                    margin-left: -8px;
                    top: 32px;
                    width: 80px;
                    height: 3.4px;
                    background-color: #414141;
                    position: absolute;
                    // animation: anim_ation .3s ease;
                }

                .underline4 {
                    margin-left: -8px;
                    top: 32px;
                    width: 90px;
                    height: 3.4px;
                    background-color: #414141;
                    position: absolute;
                    // animation: anim_ation .3s ease;
                }

                .underline5 {
                    margin-left: -8px;
                    top: 32px;
                    width: 125px;
                    height: 3.4px;
                    background-color: #414141;
                    position: absolute;
                    // animation: anim_ation .3s ease;
                }

                @keyframes anim_ation {
                    0% {
                        width: 0px
                    }

                    100% {
                        // left:50px
                        width: 60px
                    }
                }
            }
        }

        .right {
            display: flex;
            align-items: center;
            gap: 5px;

            div {
                padding: 0 5px;
                background-color: $primary-white-color;
                border-radius: 10px;
                border: 1px solid #ccc;

                select {
                    padding: 6px;
                    border: none;
                    outline: none;
                    background-color: $primary-white-color;
                    color: $secondary-black-color;
                    font-size: 14px;
                    border-radius: 10px;
                }

            }
        }
    }
}