@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.category {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  background-color: $primary-white-color;
  width: 100%;
  gap: 10px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 13px;
      margin-left: 10px;
    }

    @include md {
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;

      .header {
        width: 100%;
        justify-content: space-between;
        flex-direction: row-reverse;
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      .ico {
        font-size: 22px;
        color: $secondary-black-color;
      }

      .Searchbar {
        background-color: #e8edf2;
        // width: 100%;
        display: flex;
        align-items: center;
        padding: 6px;
        border-radius: 10px;

        .icon {
          color: #4f7396;
          font-size: 18px;
        }

        input {
          width: 100%;
          border: none;
          padding-left: 5px;
          font-size: 14px;
          color: #4f7396;
          background-color: transparent;

          &:focus {
            outline: none;
          }

          &::placeholder {
            font-size: 12px;
            color: #4f7396;
          }
        }
      }

      .ico {
        color: $secondary-black-color;
        cursor: pointer;
      }
    }
  }

  .container {
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
    gap: 5px;
    overflow-x: scroll;

    @include xl {
      flex-direction: column;
      flex-direction: column-reverse;
    }

    // @include md{
    //     flex-direction: column;
    //     flex-direction: column-reverse;

    // }

    .list {
      display: flex;
      flex: 2;
      flex-direction: column;
      gap: 5px;
      width: 100%;

      // span {}
    }

    .add {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 10px 20px 40px 20px;
      box-shadow: 1px 1px 1px #c5c1c1;
      border-radius: 5px;

      @include xl {
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
      }

      span {
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;

        @include xl {
          width: 100%;
        }
      }

      p {
        width: 100%;
        font-size: 12px;
        padding: 8px;
        border-radius: 5px;
        background-color: rgba(155, 26, 26, 0.678);
        margin-bottom: 5px;
        color: $secondary-white-color;
      }

      .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        @include xl {
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
        }

        .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
          @include xl {
            flex-direction: row;
            flex-wrap: wrap;
            // width: 100%;
            // width: 30%;
          }
        }

        .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
          height: 20px;
        }
        input,
        select {
          width: 100%;
          outline: none;
          padding: 5px;
        }

        button {
          height: 35px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border: none;
          background-color: $secondary-green-color;
          color: $primary-white-color;

          &:hover {
            border: 1px solid $secondary-green-color;
            background-color: transparent;
            color: $secondary-green-color;
          }

          .circular {
            color: $primary-white-color;
            margin-top: 2px;
          }
        }
      }
    }
  }
}
