@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.edit_category {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101c8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;

  .dialog {
    background-color: aliceblue;
    width: 40vw;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
    padding: 15px;

    @include md {
      width: 70vw;
    }

    .header {
      width: 100%;
      background-color: #e8f0ea;

      .title {
        display: flex;
        flex-direction: row;
        gap: 5px;
        padding: 5px 8px;
        align-items: center;
        width: 100%;

        .title_left {
          display: flex;
          align-items: center;
          gap: 5px;
          .ico {
            font-size: 17px;
          }
          span {
            color: $primary-black-color;
            font-size: 15px;
            font-weight: bold;
          }
          .errorMsg {
            font-size: 12px;
            color: $primary-red-color;
            font-weight: 300;
            margin-left: 10px;
            font-style: italic;
          }
        }
        .ico {
          font-size: 17px;
          cursor: pointer;
        }
      }
    }

    .body {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 10px;

      .item {
        display: flex;
        flex-direction: column;
        width: 48%;
        gap: 5px;

        @include sm {
          width: 80%;
          margin-left: 10%;
        }

        label {
          color: #353535;
          font-size: 12px;
        }

        input {
          height: 30px;
          width: 100%;
          border: 1px solid #999;
          background-color: transparent;
          border-radius: 1px;
          padding: 0px 20px;

          &:focus {
            outline: none;
          }
        }

        select {
          height: 30px;
          width: 100%;
          border: 1px solid #999;
          border-radius: 2px;
          background-color: transparent;
          padding: 5px 20px;
          color: $secondary-black-color;

          &:focus {
            outline: none;
          }
        }
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 20px;

      button {
        border: none;
        padding: 5px 10px;
        background-color: transparent;
        cursor: pointer;
        width: 80px;

        &:first-child {
          border: 1px solid $secondary-black-color;
          color: $secondary-black-color;
          background-color: transparent;

          &:hover {
            border: 1px solid $primary-red-color;
            color: $primary-red-color;
          }
        }

        &:last-child {
          background-color: $secondary-green-color;
          border: 1px solid $secondary-green-color;
          color: $primary-white-color;

          &:hover {
            border: 1px solid $secondary-green-color;
            background-color: $secondary-white-color;
            color: $secondary-green-color;
          }

          .circular {
            color: $primary-white-color;
          }
        }
      }
    }
  }
}
