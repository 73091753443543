@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .primary {
    display: flex;
    flex-direction: column;

    // @include sm {
    //   background-color: black;
    // }

    .icon_chart {
      @include xxl {
        display: none;
      }
      @include md {
        margin-left: 10px;
        display: block;
      }
    }

    .menu_active {
      padding: 10px 10px;
      background-color: rgba(9, 180, 43, 0.208);
      // background-color: $secondary-white-color;
      border-radius: 5px;

      &.cl {
        margin-top: 10px;
        padding: 4px 10px;
      }

      .item {
        display: flex;
        align-items: center;
        gap: 10px;

        .icon {
          font-size: 20px;
        }

        span {
          font-size: 12px;

          @include md {
            display: none;
          }
        }
      }
    }

    .menu_item {
      padding: 10px 10px;

      &.cl {
        margin-top: 10px;
        padding: 4px 10px;
      }

      &:hover {
        background-color: $secondary-white-color;
        border-radius: 5px;
      }

      .item {
        display: flex;
        align-items: center;
        gap: 10px;

        .icon {
          font-size: 20px;
        }

        span {
          font-size: 12px;
          @include md {
            display: none;
          }
        }
      }
    }

    .active {
      border-left: 5px solid $primary-green-color;

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background-color: $primary-green-color;
        margin-left: 10px;
        border-radius: 5px;

        @include md {
          display: none;
        }

        .icon {
          color: $primary-white-color;
        }

        span {
          padding: 10px 0px;
          color: $primary-white-color;
          font-size: 12px;
        }
      }
    }
  }

  .secondary {
    margin-top: 100px;
    border-top: 2px solid $secondary-white-color;
    display: flex;
    flex-direction: column;

    .menu_item {
      padding: 10px 10px;

      &:hover {
        background-color: $secondary-white-color;
        border-radius: 5px;
      }

      .item {
        display: flex;
        align-items: center;
        gap: 10px;

        .icon {
          font-size: 20px;
        }

        span {
          font-size: 12px;

          @include md {
            display: none;
          }
        }
      }
    }

    .menu_active {
      padding: 10px 10px;
      background-color: $secondary-white-color;

      .item {
        display: flex;
        align-items: center;
        gap: 10px;

        .icon {
          font-size: 20px;
        }

        span {
          font-size: 12px;
          @include md {
            display: none;
          }
        }
      }
    }
  }
}
