.chartCircularContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.sk {
    gap: 0;
  }
  .chartHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: #050f35d2;
      font-size: 11px;
      width: 100%;
      font-family: MontserratMedium;
      font-weight: light;
    }
  }
  .box1 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px;
    border: 2px solid #b1afaf1c;
    border-radius: 4px;
    font-family: "lato", "Times New Roman", Times, serif;

    .header {
      display: flex;
      justify-content: space-between;
      font-family: MontserratMedium;

      .left {
        display: flex;
        flex-direction: column;
        .tt {
          font-size: 11px;
          color: #050f35;
        }
        .s_tt {
          font-size: 11px;
          color: #050f35;
          font-family: MontserratThin;
        }
      }
      .count {
        font-size: 18px;
        font-weight: bold;
        color: #050f35;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      color: #888888;
      font-size: 10px;
    }
  }
  .progression {
    display: flex;
    flex-direction: column;

    .progress {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      .croiss {
        color: #050f35d2;
        font-size: 11px;
        font-family: MontserratMedium;
      }

      .ico_up {
        font-size: 15px;
        color: #09bc0f;
        font-weight: bold;
      }
      .ico_down {
        font-size: 15px;
        color: #b90f0f;
        font-weight: bold;
      }
    }
  }
}
