@import "../../styles/variables.scss";

.user_table {
  font-size: 12px;

  .css-1yhpg23-MuiTableCell-root {
    padding: 8px;
    font-size: 12px;
  }

  .css-177gid-MuiTableCell-root {
    padding: 8px;
    font-size: 12px;
  }
  .css-1azl6jz-MuiTableCell-root {
    font-weight: 600;
  }

  .css-1azl6jz-MuiTableCell-root {
    font-size: 12px;
    font-family: PoppinsRegular;
    font-weight: bolder;
  }
  .css-177gid-MuiTableCell-root {
    font-size: 12px;
    font-family: PoppinsRegular;
  }

  .editer {
    font-size: 12px;
    color: #4994d1;
    margin-right: 10px;
    cursor: pointer;
    padding: 8px;
  }

  .supprimer {
    font-size: 12px;
    color: #6c3737;
    text-decoration: underline;
    cursor: pointer;
  }
}
