@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.user_config {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  background-color: $primary-white-color;
  width: 100%;
  gap: 10px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    @include md {
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;

      .header {
        width: 100%;
        justify-content: flex-start;
      }
    }

    span {
      font-size: 13px;
      margin-left: 10px;
    }

    .header {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
      gap: 10px;

      .Searchbar {
        background-color: #e8edf2;
        // width: 100%;
        display: flex;
        align-items: center;
        padding: 6px;
        border-radius: 10px;

        .icon {
          color: #4f7396;
          font-size: 18px;
        }

        input {
          width: 100%;
          border: none;
          padding-left: 5px;
          font-size: 14px;
          color: #4f7396;
          background-color: transparent;

          &:focus {
            outline: none;
          }

          &::placeholder {
            font-size: 12px;
            color: #4f7396;
          }
        }
      }

      .ico {
        color: $secondary-black-color;
        cursor: pointer;
      }
    }
  }
}
