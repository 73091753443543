@import "../../../styles/responsive.scss";
@import "../../../styles/variables.scss";

.list_vente_page {
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-width: 100%;

  .search {
    // padding-left: 20px;
    // position: absolute;
    // top: 2.4%
    width: 100%;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .produits {
      display: flex;
      align-items: center;
      gap: 5px;
      justify-content: space-between;

      span {
        padding: 5px 8px;
        font-size: 12px;
        font-weight: 100;
        color: $primary-green-color;
        background-color: $soft-green-color;
      }
    }
  }

  .table_list {
    .responsive-table {
      font-family: "lato", sans-serif;
      font-size: 12px;
      li {
        border-radius: 3px;
        padding: 10px 30px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;

        @include sm {
          padding: 10px 20px;
        }
      }
      .table-header {
        background-color: #95a5a6;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.03em;
      }
      .table-row {
        background-color: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }
      .col-1 {
        flex-basis: 10%;
      }
      .col-2 {
        flex-basis: 40%;
      }
      .col-3 {
        flex-basis: 25%;
      }
      .col-4 {
        flex-basis: 25%;
      }

      @media all and (max-width: 767px) {
        .table-header {
          display: none;
        }
        // .table-row{

        // }
        li {
          display: block;
        }
        .col {
          flex-basis: 100%;
        }
        .col {
          display: flex;
          padding: 10px 0;
          &:before {
            color: #6c7a89;
            padding-right: 10px;
            content: attr(data-label);
            flex-basis: 50%;
            text-align: right;
          }
        }
      }
    }
  }
}
