.connexionBar {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  // background-color: #35170c;
  // background-color: #4b2c0481;
  background-color: #5a0707;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  color: #ccc7c7;
  z-index: 99999;

  .sp1 {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: "MonserratRegular", Arial, sans-serif;

    .ico {
      font-size: 20px;
      font-weight: lighter;
    }
  }
  .sp2 {
    font-family: "lato", Arial, sans-serif;
    text-decoration: underline;
    cursor: pointer;
    font-style: oblique;
  }
}
