@import '../../../styles/variables.scss';

    .systeme_notification {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;

        .box_rqt {
            display: flex;
            flex-direction: column;
            cursor: pointer;
        

            .rqt {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: $primary-white-color;
                border: 1px solid #dbdce2;
                border-radius: 10px;
                padding: 20px;
                width: 100%;

                .left {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    flex-direction: column;

                    .text {
                        font-size: 12px;
                        font-weight: 550;
                    }

                    .moment{
                        font-size: 12px;
                        color: $primary-green-color;
                        font-weight: normal;
                        font-style: italic;
                    }
                }

                .icons {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .unread {
                        width: 14px;
                        height: 14px;
                        border-radius: 50%;
                        background-color: $primary-green-color;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .icon {
                        font-size: 18px;
                        cursor: pointer;

                    }
                }
            }

            @keyframes slideOpacityAnimation {
                from {
                    transform: translateY(-10%);
                    opacity: 0;
                }

                to {
                    transform: translateY(0%);
                    opacity: 1;
                }
            }

            .response {
                margin-top: -10px;
                background-color: $primary-white-color;
                border: 1px solid #dbdce2;
                border-radius: 0px 0px 10px 10px;
                padding: 20px;
                width: 100%;
                position: relative;

                &.animation {
                    animation: slideOpacityAnimation 500ms;
                }

                .icons {
                    display: flex;
                    gap: 10px;
                    position: absolute;
                    bottom: 10px;
                    right: 10px;



                    .icon {
                        background-color: $primary-white-color;
                        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
                        height: 30px;
                        width: 30px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        &:hover {
                            background-color: $secondary-green-color;
                            color: $primary-white-color;
                        }

                        .ico {
                            font-size: 16px;
                            color: $secondary-black-color
                        }
                    }
                }

                .text {
                    font-size: 12px;
                    color: $secondary-black-color;
                }
            }

            .inactive {
                display: none;
            }
        }
    }
