@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.store_page {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include sm {
      font-size: 12px;
      gap: 12px;
    }

    .left {
      display: flex;
      align-items: center;
      gap: 10px;

      .ico {
        font-size: 17px;
      }
    }

    .right {
      display: flex;
      gap: 10px;

      .btn_update {
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 6px 10px;
        // border: 1px solid $secondary-green-color;
        cursor: pointer;
        background-color: transparent;
        color: $secondary-black-color;
        gap: 3px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        // border-radius: 5px;

        .ico {
          font-size: 16px;
        }

        @include sm {
          display: none;
        }

        @include md {
          font-size: 10px;
        }
      }

      .btn_update_sm {
        display: flex;
        align-items: center;
        font-size: 11px;
        padding: 4px 8px;
        // border: 1px solid $secondary-green-color;
        cursor: pointer;
        background-color: transparent;
        color: $secondary-black-color;
        gap: 3px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        // border-radius: 4px;

        .ico {
          font-size: 16px;
        }

        @include xxl {
          display: none;
        }

        @include sm {
          display: flex;
        }
      }

      .btn_commande {
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 6px 10px;
        border: 1px solid $secondary-black-color;
        cursor: pointer;
        background-color: $secondary-green-color;
        color: $primary-white-color;

        .ico {
          font-size: 14px;
        }
      }
      button {
        border-radius: 4px;
        cursor: pointer;
        padding: 6px;
        font-size: 12px;
        color: $primary-black-color;
        background-color: transparent;
        border: 1px solid $secondary-black-color;
        display: flex;
        align-items: center;
        gap: 5px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        .ico {
          font-size: 17px;
        }

        &:hover {
          box-shadow: none;
        }
      }
    }

    .right_btn {
      display: flex;
      gap: 10px;
      align-items: center;
      // color: alpha($color: #1057803c);
      // color: alpha($color: #6e8ea03c);

      button {
        border-radius: 4px;
        cursor: pointer;
        padding: 6px 10px;
        font-size: 12px;
        color: $primary-black-color;
        // background-color: transparent;
        border: 1px solid $secondary-black-color;
        display: flex;
        align-items: center;
        gap: 5px;

        .ico {
          font-size: 17px;
        }

        &:hover {
          // box-shadow: none;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
      }
    }
  }

  .container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(200px, auto);
    margin-top: 20px;

    @include md {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }

    .box_store {
      padding: 20px;
      box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
      background-color: $primary-white-color;
      border-radius: 10px;

      &.box1 {
        grid-column: span 2;
      }

      &.box2 {
        grid-row: span 2;
        @include lg {
          display: none;
        }
      }
      &.box3 {
        grid-column: span 1;

        @include sm {
          grid-column: span 2;
        }
      }
      &.box4 {
        grid-column: span 1;
        @include sm {
          grid-column: span 2;
        }
      }
      &.box5 {
        grid-column: span 1;
        @include sm {
          grid-column: span 2;
        }
      }
      &.box6 {
        grid-column: span 2;
      }

      @include sm {
        padding: 10px;
      }
    }
  }
}
