@import "../../../styles/variables.scss";

.responsive-table {
  font-family: "lato", sans-serif;
  font-size: 12px;
  li {
    border-radius: 3px;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  .table-header {
    background-color: #95a5a6;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }
  .table-row {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .col-1 {
    flex-basis: 10%;
  }
  .col-2 {
    flex-basis: 40%;
    &.Ese {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      .container-Ese {
        width: 15px;
        height: 15px;
        background-color: #607955df;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        .Ese {
          font-size: 11px;
          font-weight: normal;
        }
      }
    }
  }
  .col-3 {
    flex-basis: 25%;
  }
  .col-4 {
    flex-basis: 25%;

    &.statusContainer {
      display: flex;
      align-items: center;

      .status {
        padding: 2px 5px;
        border-radius: 5px;

        &.validate {
          font-size: 10px;
          color: $primary-green-color;
          background-color: $soft-green-color;
        }

        &.reserved {
          font-size: 10px;
          color: $primary-reserved-color;
          background-color: $soft-reserved-color;
        }
        &.pending {
          font-size: 10px;
          color: $primary-orange-color;
          background-color: $soft-orange-color;
        }
        &.canceled {
          font-size: 10px;
          color: $primary-red-color;
          background-color: $soft-red-color;
        }
        &.dette {
          font-size: 10px;
          color: $primary-notify-color;
          background-color: $soft-notify-color;
        }
      }
      .ico {
        font-size: 15px;
        margin-left: 8px;
      }

      .iconoir--twitter-verified-badge {
        display: inline-block;
        margin-left: 5px;
        width: 1rem;
        height: 1rem;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='%23016a13' stroke-width='2.55'%3E%3Cpath d='M10.521 2.624a2 2 0 0 1 2.958 0l1.02 1.12a2 2 0 0 0 1.572.651l1.513-.07a2 2 0 0 1 2.092 2.09l-.071 1.514a2 2 0 0 0 .651 1.572l1.12 1.02a2 2 0 0 1 0 2.958l-1.12 1.02a2 2 0 0 0-.651 1.572l.07 1.513a2 2 0 0 1-2.09 2.092l-1.514-.071a2 2 0 0 0-1.572.651l-1.02 1.12a2 2 0 0 1-2.958 0l-1.02-1.12a2 2 0 0 0-1.572-.651l-1.513.07a2 2 0 0 1-2.092-2.09l.071-1.514a2 2 0 0 0-.651-1.572l-1.12-1.02a2 2 0 0 1 0-2.958l1.12-1.02a2 2 0 0 0 .651-1.572l-.07-1.513a2 2 0 0 1 2.09-2.092l1.514.071a2 2 0 0 0 1.572-.651z'/%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m9 12l2 2l4-4'/%3E%3C/g%3E%3C/svg%3E");
      }
      .iconoir--twitter-verified-badge_rouge {
        display: inline-block;
        margin-left: 5px;
        width: 1rem;
        height: 1rem;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='%23e4ad06' stroke-width='3'%3E%3Cpath d='M10.521 2.624a2 2 0 0 1 2.958 0l1.02 1.12a2 2 0 0 0 1.572.651l1.513-.07a2 2 0 0 1 2.092 2.09l-.071 1.514a2 2 0 0 0 .651 1.572l1.12 1.02a2 2 0 0 1 0 2.958l-1.12 1.02a2 2 0 0 0-.651 1.572l.07 1.513a2 2 0 0 1-2.09 2.092l-1.514-.071a2 2 0 0 0-1.572.651l-1.02 1.12a2 2 0 0 1-2.958 0l-1.02-1.12a2 2 0 0 0-1.572-.651l-1.513.07a2 2 0 0 1-2.092-2.09l.071-1.514a2 2 0 0 0-.651-1.572l-1.12-1.02a2 2 0 0 1 0-2.958l1.12-1.02a2 2 0 0 0 .651-1.572l-.07-1.513a2 2 0 0 1 2.09-2.092l1.514.071a2 2 0 0 0 1.572-.651z'/%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m9 12l2 2l4-4'/%3E%3C/g%3E%3C/svg%3E");
      }
    }
  }

  @media all and (max-width: 767px) {
    .table-header {
      display: none;
    }
    // .table-row{

    // }
    li {
      display: block;
    }
    .col {
      flex-basis: 100%;
    }
    .col {
      display: flex;
      padding: 10px 0;
      &:before {
        color: #6c7a89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 50%;
        text-align: right;
      }
    }
  }
}
