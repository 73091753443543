@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.user_table {
  font-size: 10px;
  background-color: #d1d1d117;
  font-family: "PoppinsRegular";

  .btn {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
  }
  .editer {
    font-size: 12px;
    color: #4994d1;
    margin-right: 10px;
    cursor: pointer;
  }

  .css-1ygcj2i-MuiTableCell-root {
    font-size: 12px;
    font-family: PoppinsRegular;
    font-weight: bolder;
  }

  .css-1ex1afd-MuiTableCell-root {
    font-size: 12px;
    font-family: PoppinsRegular;
  }

  // .rowHeader {
  //   background-color: #a4c1ad6c;
  // }

  .supprimer {
    font-size: 12px;
    color: #6c3737;
    text-decoration: underline;
    cursor: pointer;
  }
}

.loader {
  margin-top: -20px;
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2px;

  span {
    font-style: italic;
    font-size: 12px;
    color: #333;
  }

  @include lg {
    height: 340px;
  }
}
