@import '../../styles/variables.scss';
@import '../../styles/responsive.scss';

.single_reservation_page {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include md {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        }

        .left {
            display: flex;
            align-items: center;
            gap: 10px;

            h3 {
                @include lg {
                    font-size: 14px;
                }
            }

            .ico {
                font-size: 17px;

                @include md {
                    font-size: 10px;
                }
            }
        }

        .right {
            display: flex;
            align-items: center;
            gap: 10px;

            @include md {
               justify-content: center;
               width: 100%;

            }

            .btn_sortie {
                color: $primary-white-color;
                background-color: rgb(17, 192, 133);
                border-radius: 3px;
                cursor: pointer;
                padding: 6px;
                font-size: 12px;
                font-weight: bold;
                // border: 1px solid $secondary-black-color;
                display: flex;
                align-items: center;
                gap: 5px;
                border: none;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;


                .ico {
                    font-size: 17px;
                }

                &:hover {
                    box-shadow: none;

                }
            }

            button {
                border-radius: 4px;
                cursor: pointer;
                padding: 6px;
                font-size: 12px;
                color: $primary-black-color;
                background-color: transparent;
                border: 1px solid $secondary-black-color;
                display: flex;
                align-items: center;
                gap: 5px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;


                .ico {
                    font-size: 17px;
                }

                &:hover {
                    box-shadow: none;

                }
            }
        } 


    }

    .container {
        display: flex;
        padding: 20px;
        box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
        background-color: $primary-white-color;
        border-radius: 10px;
        position: relative;

        @include md {
            flex-direction: column;
            gap: 20px;
        }

        .left {
            flex: 1;
            position: sticky;
            align-self: flex-start;
            top: 0px;

            @include md {
                position: unset;
            }

            .top {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .box {
                    display: flex;
                    align-items: center;
                    gap: 15px;

                    .box_icon {
                        width: 40px;
                        height: 40px;
                        background-color: #dfe4e1;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .ico2 {
                            color: $secondary-black-color;
                        }
                    }

                    .box_info {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        align-items: flex-start;

                        .main_info {
                            font-weight: bold;
                        }

                        .second_info {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;

                            span {
                                color: $secondary-black-color;
                                font-size: 12px;

                            }
                        }
                    }

                }

                .avatar {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    gap: 10px;
                    padding-bottom: 10px;
                    padding-right: 10px;
                    // border-bottom: 1px solid $secondary-black-color;

                    img {
                        width: 100px;
                        height: 70px;
                        object-fit: contain;
                        background-color: #dfe4e1;
                        padding: 5px;
                        border-radius: 5px;

                    }



                    .produit {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 5px;

                        .username {
                            font-weight: 700;
                            font-size: 14px;
                        }

                        .info {
                            color: $secondary-black-color;
                            font-size: 12px;
                        }

                        .status {
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            .info {
                                color: $secondary-black-color;
                                font-size: 12px;
                            }

                            .encours {
                                padding: 2px 5px;
                                font-size: 11px;
                                background-color: $soft-orange-color;
                                border-radius: 3px;
                                color: $primary-orange-color;
                            }

                            .sortie {
                                padding: 2px 5px;
                                font-size: 11px;
                                background-color: $soft-green-color;
                                border-radius: 3px;
                                color: $primary-green-color;
                            }

                            .horsdelai {
                                padding: 2px 5px;
                                font-size: 11px;
                                background-color: $soft-red-color;
                                border-radius: 3px;
                                color: $primary-red-color;
                            }


                        }


                    }
                }

                .body {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;
                    padding-right: 20px;

                    .box {
                        display: flex;
                        align-items: flex-start;
                        gap: 15px;

                        .box_icon {
                            width: 40px;
                            min-height: 40px;
                            min-width: 40px;
                            height: 40px;
                            background-color: #dfe4e1;
                            border-radius: 5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .ico2 {
                                color: $secondary-black-color;
                            }
                        }

                        .box_info {
                            margin-top: 3px;
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                            align-items: flex-start;

                            .main_info {
                                font-weight: bold;
                            }

                            .second_info {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;

                                span {
                                    color: $secondary-black-color;
                                    font-size: 12px;
                                }
                            }
                        }

                    }
                }
            }


        }

        .right {
            flex: 1;

            .title {
                font-size: 14px;
                font-weight: bold;

            }

            ul {
                margin-top: 10px;

                li {
                    list-style-type: none;
                    position: relative;
                    width: 2px;
                    padding-top: 20px;
                    background-color: $primary-green-color;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0px;
                        left: 50%;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: $primary-green-color;
                        transform: translateX(-50%);
                    }

                    div {
                        min-width: 400px;
                        width: 400px;
                        padding: 8px;
                        background-color: #5bca2b23;
                        border-radius: 0 5px 5px 0;

                        @include md{
                            min-width: 350px;
                            width: max-content;
                          }

                        p {
                            margin-bottom: 5px;
                            font-size: 12px;
                        }

                        time {
                            font-size: 10px;
                        }
                    }
                }
            }

        }
    }
}