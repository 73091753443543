@import '../../styles/variables.scss';
@import '../../styles/responsive.scss';

.page_notFound {
    width: 100vw;
    height: 100vh;
    display: flex;

    @include md {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;

        @include md {
            margin-top: 100px;
        }

        h2 {
            font-size: 30px;
        }

        h1 {
            font-size: 100px;
        }

        span {
            padding: 5px 10px;
            border: 1px solid $primary-green-color;
            font-size: 12px;
            color: $primary-black-color ;
            background-color: $primary-white-color;
            cursor: pointer;

            &:hover {
                background-color: $primary-green-color;
                color: $primary-white-color;
                border: 1px solid $primary-green-color;
            }
        }

    }

    .right {
        flex: 1;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;

        img {
            width: 600px;
            height: 250px;
            object-fit: cover;

            @include lg {
                width: 300px;
                height: 150px;
                margin-left: -100px;
            }

            @include md {
                margin-left: 0;
            }
        }
    }
}