@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.configuration {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;

      .ico {
        @include sm {
          font-size: 16px;
        }
      }

      .title {
        font-size: 18px;
        font-weight: 500;

        @include md {
          font-size: 12px;
        }

        @include sm {
          font-size: 12px;
        }
      }
    }

    .right {
      button {
        border-radius: 4px;
        cursor: pointer;
        padding: 6px;
        font-size: 12px;
        color: $primary-black-color;
        background-color: transparent;
        border: 1px solid $secondary-black-color;
        display: flex;
        align-items: center;
        gap: 5px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        .ico {
          font-size: 17px;
        }

        &:hover {
          box-shadow: none;
        }

        @include sm {
          display: none;
        }
      }
      .btn_sm {
        border-radius: 4px;
        cursor: pointer;
        padding: 6px;
        font-size: 12px;
        color: $primary-black-color;
        background-color: transparent;
        border: 1px solid $secondary-black-color;
        display: flex;
        align-items: center;
        gap: 5px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        .ico {
          font-size: 15px;
        }

        &:hover {
          box-shadow: none;
        }

        @include xxl {
          display: none;
        }
        @include sm {
          display: flex;
        }
      }
    }
  }

  .options {
    display: flex;
    gap: 10px;
    padding: 10px;
    border-bottom: 2px solid $secondary-black-color;

    .option {
      padding: 4px 6px;
      border-radius: 5px;
      background-color: #dfe4e1;
      display: flex;
      align-items: center;
      gap: 5px;

      .ico {
        font-size: 17px;
      }

      &:hover {
        background-color: #c8cdca;
      }

      span {
        font-size: 12px;
        color: $secondary-black-color;
        cursor: pointer;

        @include md {
          display: none;
        }
        &.span_sm {
          font-size: 12px;
          color: $secondary-black-color;
          cursor: pointer;
          @include xxl {
            display: none;
          }
          @include md {
            display: block;
          }
        }
      }
    }

    .option_active {
      padding: 4px 6px;
      border-radius: 5px;
      background-color: #b7e6ca;
      display: flex;
      align-items: center;
      gap: 5px;

      .ico {
        font-size: 17px;
      }

      span {
        font-size: 12px;
        color: $secondary-black-color;
        cursor: pointer;

        @include md {
          display: none;
        }
        &.span_sm {
          font-size: 12px;
          color: $secondary-black-color;
          cursor: pointer;
          @include xxl {
            display: none;
          }
          @include md {
            display: block;
          }
        }
      }
    }

    // .container{
    // }
  }
}
