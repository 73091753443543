@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.order_list_page {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;

      .ico {
        font-size: 17px;

        @include md {
          font-size: 12px;
        }
      }

      .title {
        font-size: 18px;
        font-weight: 500;
      }
    }

    .right {
      display: flex;
      gap: 10px;
      .btn_commande {
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 6px 10px;
        border: 1px solid $secondary-black-color;
        cursor: pointer;
        background-color: $secondary-green-color;
        color: $primary-white-color;
        @include md {
          display: none;
        }
        .ico {
          font-size: 14px;
        }
      }
      .ico_sm {
        background-color: $secondary-green-color;
        color: $secondary-white-color;
        border-radius: 50%;
        padding: 4px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: none;

        &:hover {
          transition: 0.3s;
          transform: scale(1.1);
        }
        .ico_sm_ico {
          font-size: 20px;
          transition: 0.3s;
          &:hover {
            transform: scale(1.3);
          }
        }

        @include xxl {
          display: none;
        }
        @include md {
          display: flex;
        }
      }
      button {
        border-radius: 4px;
        cursor: pointer;
        padding: 6px;
        font-size: 12px;
        color: $primary-black-color;
        background-color: transparent;
        border: 1px solid $secondary-black-color;
        display: flex;
        align-items: center;
        gap: 5px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        @include md {
          display: none;
        }
        .ico {
          font-size: 17px;
        }

        &:hover {
          box-shadow: none;
        }
      }
    }
  }

  .options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #dfe4e1;

    @include lg {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-bottom: none;
    }

    .link_sm {
      @include md {
        display: none;
      }
    }

    .left {
      display: flex;
      gap: 20px;
      padding: 10px;
      width: 100%;
      align-items: center;

      @include lg {
        border-bottom: 2px solid #dfe4e1;
        gap: 15px;
      }

      @include md {
        gap: 3px;
      }

      .option {
        padding: 4px 10px;
        border-radius: 5px;
        cursor: pointer;

        @include md {
          padding: 4px 8px;
        }

        span {
          font-size: 12px;
          color: $secondary-black-color;
          cursor: pointer;
          font-weight: bold;

          &:last-child {
            font-weight: bold;
            margin-left: 10px;
          }
        }

        .count {
          padding-inline: 5px;
        }

        &:hover {
          background-color: #ffffff;
        }
      }

      .option_active {
        position: relative;
        padding: 4px 10px;
        border-radius: 5px;
        box-shadow: 0px 0px 4px #e4e4e4;
        background-color: $primary-white-color;

        cursor: pointer;

        span {
          font-size: 12px;
          font-weight: bold;
          color: $primary-black-color;
          cursor: pointer;

          &:last-child {
            font-weight: bold;
            margin-left: 10px;
          }
        }

        .count {
          padding-inline: 5px;
        }

        .underlinePoussin {
          margin-left: -8px;
          top: 32px;
          width: 85px;
          height: 3.4px;
          background-color: #414141;
          position: absolute;
          // animation: anim_ation .3s ease;
        }

        .underlineAll {
          margin-left: -8px;
          top: 32px;
          width: 69px;
          height: 3.4px;
          background-color: #414141;
          position: absolute;
          // animation: anim_ation .3s ease;
        }
        .underline2 {
          margin-left: -8px;
          top: 32px;
          width: 93px;
          height: 3.4px;
          background-color: #414141;
          position: absolute;
          // animation: anim_ation .3s ease;
        }

        .underline3 {
          margin-left: -8px;
          top: 32px;
          width: 74px;
          height: 3.4px;
          background-color: #414141;
          position: absolute;
          // animation: anim_ation .3s ease;
        }

        .underline4 {
          margin-left: -8px;
          top: 32px;
          width: 87px;
          height: 3.4px;
          background-color: #414141;
          position: absolute;
          // animation: anim_ation .3s ease;
        }

        .underlinePoulet {
          margin-left: -8px;
          top: 32px;
          width: 76px;
          height: 3.4px;
          background-color: #414141;
          position: absolute;
          // animation: anim_ation .3s ease;
        }
        .underlinePoule {
          margin-left: -8px;
          top: 32px;
          width: 73px;
          height: 3.4px;
          background-color: #414141;
          position: absolute;
          // animation: anim_ation .3s ease;
        }
        .underline6 {
          margin-left: -8px;
          top: 32px;
          width: 70px;
          height: 3.4px;
          background-color: #414141;
          position: absolute;
          // animation: anim_ation .3s ease;
        }

        @keyframes anim_ation {
          0% {
            width: 0px;
          }

          100% {
            // left:50px
            width: 60px;
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 5px;

      @include lg {
        justify-content: flex-end;
        width: 100%;
      }

      div {
        padding: 0 5px;
        background-color: $primary-white-color;
        border-radius: 10px;
        border: 1px solid #ccc;

        select {
          padding: 6px;
          border: none;
          outline: none;
          background-color: $primary-white-color;
          color: $secondary-black-color;
          font-size: 14px;
          border-radius: 10px;
        }
      }
    }
  }
}
