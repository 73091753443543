@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.card_recent_store {
  font-family: "lato", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 12px;
  height: 100%;
  padding-bottom: 20px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 2px;
    list-style: none;

    li {
      text-decoration: none;
      margin-bottom: 10px;
      cursor: pointer;

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          flex-direction: column;
          gap: 5px;
          width: 100%;
          padding-bottom: 10px;
          border-bottom: 1px solid #d7d7d7;

          .title {
            color: $secondary-black-color;
            font-weight: bolder;
            color: #222223;
            font-size: 12px;
          }
          .item_detail {
            display: flex;
            // justify-content: space-between;
            padding-right: 5px;

            .date {
              margin-left: 5px;
            }
          }
        }

        .plus_btn {
          font-size: 12px;
          color: $secondary-black-color;
          text-decoration: underline;
          cursor: pointer;

          .ico {
            font-size: 15px;
            color: $secondary-black-color;
          }
        }
      }
    }
  }

  .footer {
    padding: 20px;
    border-top: 1px solid #d7d7d7;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      font-size: 12px;
      font-weight: bold;
      color: $secondary-green-color;
      cursor: pointer;
    }

    .ico {
      font-size: 12px;
      color: $secondary-green-color;
    }
  }
}
