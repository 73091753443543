@import "../../../styles/variables.scss";

.biogaz_card {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: bold;
      font-size: 12px;
      font-family: "lato", Arial, Helvetica, sans-serif;
      color: rgb(37, 37, 37);
    }

    .number {
      display: flex;
      align-items: center;

      span {
        &:first-child {
          font-weight: bold;
          font-size: 22px;
        }

        &:last-child {
          padding-top: 7px;
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
  }

  .items_sk {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    margin-top: 10px;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-top: -10px;

    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      span {
        font-size: 13px;
        color: $secondary-black-color;

        &:last-child {
          font-style: italic;
          font-size: 13px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .state {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 10px;

      span {
        font-size: 12px;
        color: $secondary-black-color;
      }

      .items {
        display: flex;
        flex-direction: column;
        .item {
          display: flex;
          flex-direction: column;
          gap: 5px;
          margin-top: 10px;
          .item_header {
            display: flex;
            justify-content: space-between;
            font-family: "lato", Arial, Helvetica, sans-serif;
            font-weight: 300;

            .title {
              font-size: 12px;
            }
            .count {
              font-size: 12px;
            }
          }
          // .item_progressbar {
          // }
        }
      }
    }
  }
}
