@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.requete_notification {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  .header {
    display: flex;
    justify-content: flex-end;

    .btn_commande {
      display: flex;
      align-items: center;
      font-size: 12px;
      padding: 6px 10px;
      border: 1px solid $secondary-black-color;
      cursor: pointer;
      background-color: $secondary-green-color;
      color: $primary-white-color;
      width: max-content;
      margin-top: -180px;

      .ico {
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }

  .box_rqt {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .rqt {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $primary-white-color;
      border: 1px solid #dbdce2;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .left {
        display: flex;
        align-items: center;
        gap: 5px;
        flex: 8;
        font-size: 11px;

        .text_lg {
          display: flex;
          width: 100%;
          overflow: hidden;

          @include sm {
            display: none;
          }
          @include md {
            display: none;
          }
          @include xxl {
            display: none;
          }
        }
        .text_sm {
          display: flex;
          width: 100%;
          overflow: hidden;

          @include md {
            display: none;
          }

          @include lg {
            display: none;
          }
          @include xl {
            display: none;
          }
          @include xxl {
            display: none;
          }
        }
        .text_md {
          display: flex;
          width: 100%;
          overflow: hidden;

          @include sm {
            display: none;
          }
          @include lg {
            display: none;
          }
          @include xl {
            display: none;
          }
          @include xxl {
            display: none;
          }
        }
      }

      .icons {
        display: flex;
        align-items: center;
        gap: 5px;
        flex: 2;
        justify-content: flex-end;

        .number {
          width: 25px;
          height: 18px;
          border-radius: 50%;
          background-color: $primary-red-color;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            position: absolute;
            color: $primary-white-color;
            font-size: 10px;
            font-weight: 300;
          }
        }

        .icon {
          font-size: 18px;
          cursor: pointer;
        }
      }
    }

    @keyframes slideOpacityAnimation {
      from {
        transform: translateY(-10%);
        opacity: 0;
      }

      to {
        transform: translateY(0%);
        opacity: 1;
      }
    }

    .response {
      margin-top: -10px;
      background-color: $primary-white-color;
      border: 1px solid #dbdce2;
      border-radius: 0px 0px 10px 10px;
      padding: 20px;
      width: 100%;
      // position: relative;
      display: flex;
      flex-direction: column;
      gap: 10px;
      // background-color: #d149b4;

      &.animation {
        animation: slideOpacityAnimation 500ms;
      }

      .text {
        font-size: 12px;
        color: $secondary-black-color;

        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
      }

      .btns_ {
        display: flex;
        gap: 20px;
        justify-content: flex-end;
        align-items: center;
        font-size: 12px;
        font-style: italic;

        .date_Creation {
          color: #383e43;
        }
        .editer {
          display: flex;
          align-items: center;
          color: #4994d1;
          text-decoration: underline;
          margin-right: 10px;
          cursor: pointer;
          &:hover {
            color: #383e43;
          }

          .ico {
            font-size: 18px;
          }
        }

        .supprimer {
          display: flex;
          align-items: center;
          color: #6c3737;
          text-decoration: underline;
          cursor: pointer;

          &:hover {
            color: #383e43;
          }
          .ico {
            font-size: 18px;
          }
        }
      }
    }

    .inactive {
      display: none;
    }
  }
}
